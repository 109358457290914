import TitlePartVisit from "./Part/TitlePartVisit";
import FieldSetVisitForm from "./Part/FieldSetVisitForm";
import BaseIdentificationForm from "./Identification/BaseIdentificationForm";
import SocialIdentificationForm from "./Identification/SocialIdentificationForm";
import DisabilityIdentificationForm from "./Identification/DisabilityIdentificationForm";
import InvalidnostIdentificationForm from "./Identification/InvalidnostIdentificationForm";
import PrivichkiIdentificationForm from "./Identification/PrivichkiIdentificationForm";
import DressingQuestionsForm from "./Questions/DressingQuestionsForm";
import GettingUpQuestionsForm from "./Questions/GettingUpQuestionsForm";
import EatingQuestionsForm from "./Questions/EatingQuestionsForm";
import WalkingQuestionsForm from "./Questions/WalkingQuestionsForm";
import WalkingAidsQuestionsForm from "./Questions/WalkingAidsQuestionsForm";
import WalkingHelpQuestionsForm from "./Questions/WalkingHelpQuestionsForm";
import PersonalHygieneQuestionsForm from "./Questions/PersonalHygieneQuestionsForm";
import GettingObjectsQuestionsForm from "./Questions/GettingObjectsQuestionsForm";
import GraspingMovementsQuestionsForm from "./Questions/GraspingMovementsQuestionsForm";
import OtherActionsQuestionsForm from "./Questions/OtherActionsQuestionsForm";
import GettingObjectsAidsQuestionsForm from "./Questions/GettingObjectsAidsQuestionsForm";
import GettingObjectsHelpQuestionsForm from "./Questions/GettingObjectsHelpQuestionsForm";

const QuestionsPatientVisit = ({visit}) => {

    return <div className={" py-10 px-10  border-b"}>
        <TitlePartVisit title={"ОПРОСНИК HAQ-DI"}/>
        <DressingQuestionsForm visit={visit}/>
        <GettingUpQuestionsForm visit={visit}/>
        <EatingQuestionsForm visit={visit}/>
        <WalkingQuestionsForm visit={visit}/>
        <WalkingAidsQuestionsForm visit={visit}/>
        <WalkingHelpQuestionsForm visit={visit}/>
        <PersonalHygieneQuestionsForm visit={visit}/>
        <GettingObjectsQuestionsForm visit={visit}/>
        <GraspingMovementsQuestionsForm visit={visit}/>
        <OtherActionsQuestionsForm visit={visit}/>
        <GettingObjectsAidsQuestionsForm visit={visit}/>
        <GettingObjectsHelpQuestionsForm visit={visit}/>

    </div>
}

export default QuestionsPatientVisit;