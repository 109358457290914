
import { useParams } from 'react-router-dom';

const PatientNapravlenieContent = () => {

    const { id } = useParams();
    return <div>
        <p className={"uppercase text-xl max-w-sm font-bold leading-7 px-8 py-5"}>
            Смотрим направление пациента
        </p>
    </div>
}

export default PatientNapravlenieContent;