import React from "react";
import {Link} from "react-router-dom";

const SidebarSubMenuItem = ({name, link}) => {

    const handleClick = (event) => {
        event.preventDefault();

        const linkText = event.target.textContent.trim();
        const headers = document.querySelectorAll('.subtitle');

        headers.forEach((header) => {

            if (header.textContent.indexOf(linkText) !== -1) {
                const offset = 100; // смещение в пикселях
                const headerPosition = header.getBoundingClientRect().top + window.scrollY - offset;

                window.scroll({
                    top: headerPosition,
                    behavior: 'smooth'
                });
            }
        });
    };
    return <Link to={link} className="color-gray-dark-pa text-xs uppercase" onClick={handleClick}>{name}</Link>;
}

export default SidebarSubMenuItem;
