import TitlePartVisit from "./Part/TitlePartVisit";
import React, {useEffect, useState} from "react";
import AnalysPatientVisitForm from "./Analisis/AnalysPatientVisitForm";
import AnalysisFullListPatient from "./Analisis/AnalysisFullListPatient";
import fieldChangeProvider from "../../../../provider/FieldChangeProvider";
import Button from "../../../Part/Form/Button";
import medicineProvider from "../../../../provider/MedicineProvider";
import Select from "../../../Part/Form/Select";
import directoryProvider from "../../../../provider/DirectoryProvider";
import analysProvider from "../../../../provider/AnalysProvider";
import Row from "../../../Part/Form/Row";


const AddForm = ({visit, addAttachAnalyseInVisit}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [listBloodTestTypes, setListBloodTestTypes] = useState([]);
    const [bloodTestTypeValue, setBloodTestTypeValue] = useState([]);

    const fetchBloodTestType = async () => {
        const bloodTestTypeData = await directoryProvider.getDirectory("blood_test_type");

        if ( bloodTestTypeData.length) {
            bloodTestTypeData.unshift({"name": "Выберите значение", "value": ""});
        }
        setListBloodTestTypes(bloodTestTypeData);
    }

    useEffect(() => {
        fetchBloodTestType();
    }, [visit])


    const handleOpenAddForm = () => {
        setIsOpen(true);
    }

    const handleSetBloodTestType = (name, value) => {
        setBloodTestTypeValue(value);
    }

    const handleAddAnalyse = async () => {
        const data = await analysProvider.createAnalyse(visit.getPatient().getIIN(), visit.getId(), bloodTestTypeValue);
        if (data && data.analys_id) {
            addAttachAnalyseInVisit(data.analys_id);
            //const setMedicine = await fieldChangeProvider.setFieldTakeMedicine(data.takemedicine_id,"medicines", medicinesValue);
            //addListTakeMedicines(data.analys_id);
        }
        setIsOpen(false);
    }

    if (isOpen) {
        return <div className={"mt-2 flex gap-2"}>
            <Select name={"medicines_type"} options={listBloodTestTypes} value={""} width={"w-96"} onChange={handleSetBloodTestType}/>
            <Button color={"lite"} size={"small"} label={"Добавить"} onClick={handleAddAnalyse}/>
        </div>;
    } else {
        return <div className={"mt-2"}>
            <Button color={"lite"}  label={"Добавить анализ"} onClick={handleOpenAddForm}/>
        </div>;
    }
}

const AnalisisPatientVisit = ({visit}) => {

    const [listAnalisis, setListAnalisis ] = useState(visit.getValue("analysis"));
    const [listAnalisisPatient, setListAnalisisPatient ] = useState([]);

    const [error, setError] = useState('');
    const [commonbloodTest, setCommonbloodTest ] = useState([]);
    const [biochemicalTest, setBiochemicalTest ] = useState([]);



    useEffect(() => {
        const fetchDirectoryData = async () => {
            const commonbloodTestData = await directoryProvider.getDirectory("commonblood_test");
            setCommonbloodTest(commonbloodTestData);
            const biochemicalTestData = await directoryProvider.getDirectory("biochemical_test");
            setBiochemicalTest(biochemicalTestData);

            let analysisListPatient = visit.getPatient().getValue("analysis");
            setListAnalisisPatient(analysisListPatient);
        };

        fetchDirectoryData();
    }, [visit])


    const handleDeattachAnalyseInVisit = async (analysKey) => {
        await fieldChangeProvider.setFieldVisit(visit.getId(), "analysis", analysKey, 0);
        await fieldChangeProvider.setFieldAnalys(analysKey, "visit_id", "", 1);
        const listAnalisisData = listAnalisis.filter(item => item !== analysKey);
        setListAnalisis(listAnalisisData);
    }

    const handleAttachAnalyseInVisit = async (analysKey) => {
        if( !listAnalisis.includes(analysKey) ) {
            await fieldChangeProvider.setFieldVisit(visit.getId(), "analysis", analysKey, 1);
            await fieldChangeProvider.setFieldAnalys(analysKey, "visit_id", visit.getId(), 1);
            const listAnalisisData = [...listAnalisis];
            listAnalisisData.push(analysKey);
            setListAnalisis(listAnalisisData);
        }
    }

    const handleAddAnalyseInVisit = async (analysKey) => {
        if( !listAnalisis.includes(analysKey) ) {
            const listAnalisisData = [...listAnalisis];
            listAnalisisData.push(analysKey);
            setListAnalisis(listAnalisisData);

            const listAnalisisPatientData = [...listAnalisisPatient];
            listAnalisisPatientData.push(analysKey);
            setListAnalisisPatient(listAnalisisPatientData);
        }
    }

    return <div className={" py-10 px-10  border-b"}>

        <div className={"flex  gap-5"}>
            <div className={"w-60"}>
                <TitlePartVisit title={"Анализы"}/>
            </div>
            <AnalysisFullListPatient
                visit={visit}
                onAddAnalyse={handleAttachAnalyseInVisit}
                handleDeattachAnalyseInVisit={handleDeattachAnalyseInVisit}
                listAnalysisPatient={listAnalisisPatient}
                commonbloodTest={commonbloodTest}
                biochemicalTest={biochemicalTest}
            />
        </div>

        <Row label={""} name={""} >
            <AddForm visit={visit} addAttachAnalyseInVisit={handleAddAnalyseInVisit} />
        </Row>

        <div className={"analys flex flex-col gap-8 mt-4"}>
            {listAnalisis && listAnalisis.map((analysKey, index) => {
                return <AnalysPatientVisitForm
                    analysKey={analysKey}
                    visit={visit}
                    commonbloodTest={commonbloodTest}
                    biochemicalTest={biochemicalTest}
                />
            })}
        </div>



    </div>
}

export default AnalisisPatientVisit;