import apiService from "./ApiService";
import ONMK from "../models/ONMK";
import Tuberculosis from "../models/Tuberculosis";
import Pregnancy from "../models/Pregnancy";

class PregnancyProvider {

    async get(key) {
        const data = await apiService.post('/pregnancy_read', { pregnancy_id: key });
        if (data && data.pregnancys) {
            return new Pregnancy(data.pregnancys);
        }
        return null;
    }

    async create(iin ) {
        const data = await apiService.post('/pregnancy_create', { iin: iin  });
        if (data) {
            return data;
        }
        return null;
    }

}

const pregnancyProvider = new PregnancyProvider();
export default pregnancyProvider;