import FieldSetVisitForm from "../Part/FieldSetVisitForm";
import EditFieldVisitForm from "../Part/EditFieldVisitForm";

const OtherActionsQuestionsForm = ({visit}) => {

    const options = [{name: "Без труда", value: "Без труда"},{name: "С некоторым затруднением", value: "С некоторым затруднением"},{name: "С большим трудом", value: "С большим трудом"},{name: "Не могу выполнить", value: "Не могу выполнить"}];
    return <FieldSetVisitForm title={"Другие действия"} id={"base"}>
        <EditFieldVisitForm
            visit={visit}
            objectType={"visit"}
            fieldObject="run_errands"
            typeFieldForm={"radio"}
            label={"В состоянии ли вы ходить по делам и за покупками?"}
            options={options}
        />
        <EditFieldVisitForm
            visit={visit}
            objectType={"visit"}
            fieldObject="get_out_of_a_car"
            typeFieldForm={"radio"}
            label={"В состоянии ли вы сесть в автомобиль и выйти из него?"}
            options={options}
        />
        <EditFieldVisitForm
            visit={visit}
            objectType={"visit"}
            fieldObject="vacuuming"
            typeFieldForm={"radio"}
            label={"В состоянии ли вы выполнять такую работу как, например, пылесосить или огородничать?"}
            options={options}
        />
    </FieldSetVisitForm>
}

export default OtherActionsQuestionsForm;