
import React, {useEffect, useState} from 'react';
import directoryProvider from "../../../../provider/DirectoryProvider";
import reportProvider from "../../../../provider/ReportProvider";

const ReportItem = ({iin, reportItem}) => {

    const handleClick = () => {
        const url = reportProvider.getReportUrl(iin, reportItem.value);

        // Создаем форму для отправки POST-запроса
        const form = document.createElement('form');
        form.method = 'POST';
        form.action = url;
        form.target = '_blank'; // Открыть в новом окне

        // Добавляем форму в DOM и отправляем
        document.body.appendChild(form);
        form.submit();

        // Удаляем форму после отправки
        document.body.removeChild(form);
    }

    return <div className={"px-2 py-1 bg-white hover:bg-slate-100 cursor-pointer "} onClick={handleClick}>{reportItem.name}</div>;
}

const ReportForm = ({iin}) => {

    const [reportList, setRepostList] = useState([]);

    useEffect(() => {

        const fetchData = async () => {
            const report = await directoryProvider.getDirectory('report');
            setRepostList(report);
        }

        fetchData();

    }, [])

    return <div className={"border absolute right-4 top-0 "}>
        {

            reportList && reportList.map((reportItem, index) => {
                return <ReportItem iin={iin} reportItem={reportItem}/>
            })
        }
    </div>;
}

export default ReportForm;