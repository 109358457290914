import apiService from "./ApiService";
import IHD from "../models/IHD";

class IHDProvider {

    async getIHD(key) {
        const data = await apiService.post('/ihd_read', { ihd_id: key });
        if (data && data.ihds) {
            return new IHD(data.ihds);
        }
        return null;
    }

    async createIHD(iin ) {
        const data = await apiService.post('/ihd_create', { iin: iin  });
        if (data) {
            return data;
        }
        return null;
    }

}

const ihdProvider = new IHDProvider();
export default ihdProvider;