import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

const Select = ({ name, options, value, size = 'medium',  width = 'w-full', hasEmpty = false, onChange = () => {}, ...rest }) => {
    const [optionsInternal, setOptionsInternal] = useState(options);
    const handleChange = (e) => {
        onChange(name, e.target.value, true);
    };

    const sizeClass = {
        small: 'p-1 text-sm',
        medium: 'p-2 text-base',
        large: 'p-3 text-lg'
    };

    useEffect(() => {
        const optionsData = [...options];
        if (hasEmpty) {
            optionsData.unshift({name: "", value: ""});
        }
        setOptionsInternal(optionsData);

    },[options])

    return (
        <select name={name} className={`select-pa ${width}  ${sizeClass[size]} border border-gray-300 rounded-sm focus:outline-none focus:border-green-500`} onChange={handleChange} {...rest}>
        {optionsInternal.map((option, index) => (
            <option value={option.value} selected={value instanceof Array ? value.includes(String(option.value)) : value === String(option.value)}>{option.name}</option>
        ))}
        </select>
    );
};

Select.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func,
};

export default Select;