import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import uploadFileProvider from "../../../provider/UploadFileProvider";
import imagesProvider from "../../../provider/ImagesProvider";

const File = React.forwardRef(({ name, value, idObject, objectType, placeholder = "Загрузить файл", onChange = () => {},  ...rest }, ref) => {
    const [internalValue, setInternalValue] = useState(value);
    const [url, setUrl] = useState();

    const handleUploadFile = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const formData = new FormData();
        formData.append('file', file);
        formData.append('idd', idObject);
        formData.append('type', objectType);

        try {
            const response = await uploadFileProvider.uploadFile(formData);
            if (response.upload_id && response.upload_id.filename) {
                const url = imagesProvider.getImageUrl(response.upload_id.filename);
                setUrl(url);
                setInternalValue(response.upload_id.filename);
                //await onChange(response.upload_id.filename);
            }
        } catch (error) {
            console.error('Ошибка при загрузке файла', error);
        }
    };

    useEffect(() => {
        setInternalValue(value);
        const url = imagesProvider.getImageUrl(value);
        setUrl(url);
    }, [value]);

    return (
<div className={"flex gap-4 items-center"}>
    <div className={" px-3 h-10 gap-2  flex items-center justify-center  relative cursor-pointer " + (placeholder ? "bg-white border" : "") }>
        <svg className={"cursor-pointer"} width="4" height="20" viewBox="0 0 4 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="2" cy="2.00391" r="2" fill="black"/>
            <circle cx="2" cy="10.0039" r="2" fill="black"/>
            <circle cx="2" cy="18.0039" r="2" fill="black"/>
        </svg>
        { placeholder &&
            <span className={"text-sm text-center "}>
            {placeholder}
        </span>
        }
        <input
            ref={ref}
            type={"file"}
            name={name}
            onChange={handleUploadFile}
            className={`input-file-pa absolute opacity-0 w-full h-full cursor-pointer`}
            {...rest}
        />
    </div>
    {
        internalValue && url &&
        <a href={url} target={"_blank"} className={"w-80 underline"}>{internalValue}</a>
    }
</div>

    );
});

File.propTypes = {
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    onChange: PropTypes.func
};

export default File;