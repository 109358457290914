import AnalysResultItemTable from "./AnalysResultItemTable";

const AnalysResultTable = ({title, analys, results, indicatorsTest}) => {

    const typeAnalyses = analys.isCommonBlood() ? "commonblood_test_id" : "biochemical_test_id";

    return <div className={"flex gap-5 items-start"}>
        <div className={"w-60 text-sm"}>{title}</div>
        <div className={"analys-table grow max-w-xl"}>
            {
                indicatorsTest && indicatorsTest.map((analysResultItem, index) => {
                    let value = "";
                    if (results && results instanceof Array ) {
                        const item = results.find(item => item[typeAnalyses] == analysResultItem.value);
                        if (item) {
                            value = item.value;
                        }
                    }

                    return <AnalysResultItemTable  key={index} analysResultItem={analysResultItem} analys={analys} value={value}/>
                })
            }
        </div>
    </div> ;
}

export default AnalysResultTable;