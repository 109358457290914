import React, { useState } from 'react';
import TitleModal from "../../Part/Modal/TitleModal";
import Row from "../../Part/Form/Row";
import Button from "../../Part/Form/Button";
import FieldSet from "../../Part/Form/FieldSet";
import Form from "../../Part/Form/Form";
import Input from "../../Part/Form/Input";
import patientProvider from "../../../provider/PatientProvider";
import dateProvider from "../../../provider/DateProvider";

const CreatePatientModalForm = () => {
    const [iin, setIin] = useState("");
    const [fio, setFio] = useState("");
    const [date, setDate] = useState("");
    const [dateInternal, setDateInternal] = useState("");
    const [error, setError] = useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const result = await patientProvider.createPatient(iin, fio, date);

            if (result) {
                window.location = "/search?iin="+iin
                // Обработка успешного результата, например, закрыть модал или показать сообщение об успехе
            } else {
                setError("Не удалось создать пациента, попробуйте снова.");
            }
        } catch (err) {
            setError("Произошла ошибка: " + err.message);
        }
    };

    const handleSetDate = (name, value) => {
        setDate(value);
        setDateInternal(dateProvider.timestampToDate(value));
    }

    return (
        <div id={"create-patient-modal"} className={"modal-pa"}>
            <TitleModal label={"Создать пациента"} />
            <Form onSubmit={handleSubmit}>
                <FieldSet>
                    <Row label={"ИИН"}>
                        <Input
                            value={iin}
                            onChange={(name, value) => setIin(value)}
                            placeholder={"Введите ИИН пациента"}
                        />
                    </Row>
                    <Row label={"ФИО"}>
                        <Input
                            value={fio}
                            onChange={(name, value) => setFio(value)}
                            placeholder={"Введите ФИО пациента"}
                        />
                    </Row>
                    <Row label={"Дата рождения"}>
                        <Input
                            type={"date"}
                            width={"w-full"}
                            value={dateInternal}
                            onChange={handleSetDate}
                            close={false}
                        />
                    </Row>
                    {error && <p style={{ color: 'red' }}>{error}</p>} {/* Отображение ошибки */}
                    <Row label={""}>
                        <Button label={"Добавить"} type={"submit"} fullWidth={true} />
                    </Row>
                </FieldSet>
            </Form>
        </div>
    );
};

export default CreatePatientModalForm;