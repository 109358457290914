import React, {useEffect, useState} from 'react';

const PatientVisitPrintContent = () => {


    return (
        <h1 className={"p-10 bold"}>
            Страница печати в разработке.
        </h1>
    );

}

export default PatientVisitPrintContent;