import dateProvider from "../provider/DateProvider";
import directoryProvider from "../provider/DirectoryProvider";
import fieldChangeProvider from "../provider/FieldChangeProvider";
import apiService from "../provider/ApiService";

class Patient {
    constructor(data) {
        this.data = data;
    }

    getValue(key) {
        if (key == "diagnosis") {
        }
        return this.data[key];
    }

    async setValue(key, value, quant = null, isServerChange = false) {
        if (this.data[key] instanceof Array) {
            const newValue = quant
                ? [...this.data[key], value]
                : this.data[key].filter(v => v !== value);
            this.data[key] = newValue;
        } else {
            this.data[key] = value;
        }

        if (isServerChange) {
            const response = await fieldChangeProvider.setFieldIIN(this.getIIN(), key, value, quant);
            if (!response) {
                console.log("Error! Save Server");
            }
            return response;
        }
        this.saveStorage();
        return value;
    }

    getIIN() {
        return this.data["iin"] ?? "";
    }

    getFIO() {
        return this.data["fio"] ?? "";
    }

    getBirthday() {
        const birthdayTime = this.data["birthday_time"];
        return dateProvider.strToDate(birthdayTime);
    }

    getAge() {
        const birthdayTime = this.data["birthday_time"];
        const birthdayDate = new Date(birthdayTime * 1000);
        const today = new Date();

        let age = today.getFullYear() - birthdayDate.getFullYear();
        const monthDiff = today.getMonth() - birthdayDate.getMonth();
        const dayDiff = today.getDate() - birthdayDate.getDate();

        if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
            age--;
        }

        return age;
    }

    async getOptions(fieldObject) {
        const directoryList = ["gender", "diagnosis", "race", "education", "employment"  ];
        if (directoryList.indexOf(fieldObject) !== -1) {
            const directory = await directoryProvider.getDirectory(fieldObject);
            return directory;
        }
        return [];
    }

    saveStorage() {
        localStorage.setItem("patient", JSON.stringify(this.data));
    }

    async updateStorage() {
        const data = await apiService.post('/iin_read', { iin: this.getIIN()});
        if (data.ok && data.iins ) {
            this.data = data.iins;
            this.saveStorage();
        } else {
            console.log(data.description);
        }
    }

    async getDiagnosis() {

        const d = this.getValue("diagnosisString");
        if (d) {
            return d;
        }
        const diagnosis = await directoryProvider.getDirectory("diagnosis");
        const patientDiagnosis = this.getValue("diagnosis");
        const foundDiagnoses = diagnosis.filter(d => patientDiagnosis.includes(d.value));
        const myDiagnosis = foundDiagnoses.map(d => d.name).join('<br>');

        this.setValue("diagnosisString", myDiagnosis);
        this.saveStorage();
        return myDiagnosis;

    }
}


export default Patient;