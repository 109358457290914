
const FieldSetVisitForm = ({title, id, children }) => {
    return <div className={"field-set-visit mb-10"} id={id}>
        {(title &&
        <div className={"field-set-visit__title relative"}>
            <span className={"subtitle bg-gray-pa text-xs color-gray-dark-pa uppercase z-10 inline-block relative pr-3"}>{title}</span>
        </div>
        )}
        <div className={"flex flex-col gap-5 my-5"}>
            {children}
        </div>

    </div>
}

export default FieldSetVisitForm;