import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

const Textarea = ({ name, value, size = 'medium', borderColor = 'gray', width = 'w-full',  onChange = () => {}, placeholder, ...rest }) => {
    const [intermediateValue, setIntermediateValue] = useState(value);

    const sizeClass = {
        small: 'p-1 text-sm',
        medium: 'p-2 text-base',
        large: 'p-3 text-lg'
    };

    const borderClass = {
        gray: 'border-gray-300',
        blue: 'border-blue-500',
        red: 'border-red-500'
    };

    const handleChange = (e) => {
        setIntermediateValue(e.target.value);
    };

    const handleBlur = (e) => {
        onChange(name, e.target.value);
    };


    const onClear = () => {
        setIntermediateValue("");
        onChange(name, "");
    }

    return (
        <div className={"relative " + width }>
            <textarea
                name={name}
                onChange={handleChange}
                onBlur={handleBlur}
                className={`w-full ${sizeClass[size]} border ${borderClass[borderColor]} rounded-sm focus:outline-none focus:border-green-500`}
                placeholder={placeholder}
                {...rest}
            >{intermediateValue}</textarea>

        </div>

    );
};

Textarea.propTypes = {
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    borderColor: PropTypes.oneOf(['gray', 'blue', 'red']),
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    placeholder: PropTypes.string
};

export default Textarea;