import apiService from "./ApiService";
import IZL from "../models/IZL";
import OtherAssociatedConditions from "../models/OtherAssociatedConditions";

class OtherAssociatedConditionsProvider {

    async get(key) {
        const data = await apiService.post('/other_associated_conditions_read', { other_associated_conditions_id: key });
        if (data && data.other_associated_conditionss) {
            return new OtherAssociatedConditions(data.other_associated_conditionss);
        }
        return null;
    }

    async create(iin ) {
        const data = await apiService.post('/other_associated_conditions_create', { iin: iin  });
        if (data) {
            return data;
        }
        return null;
    }

}

const otherAssociatedConditionsProvider = new OtherAssociatedConditionsProvider();
export default otherAssociatedConditionsProvider;