import FieldSetVisitForm from "../Part/FieldSetVisitForm";
import EditFieldVisitForm from "../Part/EditFieldVisitForm";

const VnesustavTreatmentForm = ({visit}) => {

    const booleanOptions = [{name: "Нет", value: "0"}, {name: "Да", value: "1"}];
    return <FieldSetVisitForm title={"Внесуставные проявления"} id={"base"}>
        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="constitutional_symptoms" typeFieldForm={"checkbox"} label={"Конституциональные симптомы"} hasButtonNewItem={true}/>
        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="morning_stiffness" typeFieldForm={"boolean"} label={"Снижение веса"} options={booleanOptions} />
        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="lost_weight" typeFieldForm={"text"} label={"Ушедший вес (кг)"} width={"w-40"} />
        <EditFieldVisitForm
            visit={visit}
            objectType={"visit"}
            fieldObject="weight_loss_period"
            fieldYearStart={"date_of_start_weight_loss_period_time"}
            fieldYearEnd={"date_of_end_weight_loss_period_time"}
            fieldMonthStart={"month_of_start_weight_loss_period_time"}
            fieldMonthEnd={"month_of_end_weight_loss_period_time"}
            typeFieldForm={"periodMonth"}
            label={"Период  похудения"}
        />
        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="cardiovascular_system" typeFieldForm={"checkbox"} label={"Сердечно-сосудистая система"} hasButtonNewItem={true}/>
        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="lungs" typeFieldForm={"checkbox"} label={"Легкие"} hasButtonNewItem={true}/>
        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="skin" typeFieldForm={"checkbox"} label={"Кожа"} hasButtonNewItem={true}/>
        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="nervous_system" typeFieldForm={"checkbox"} label={"Нервная система"} hasButtonNewItem={true}/>
        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="muscles" typeFieldForm={"checkbox"} label={"Мышцы"} hasButtonNewItem={true}/>
        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="eyes" typeFieldForm={"checkbox"} label={"Глаза"} hasButtonNewItem={true}/>
        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="kidneys" typeFieldForm={"checkbox"} label={"Почки"} hasButtonNewItem={true}/>



    </FieldSetVisitForm>
}

export default VnesustavTreatmentForm;