import apiService from "./ApiService";
import IZL from "../models/IZL";
import NonAlcoholic from "../models/NonAlcoholic";

class NonAlcoholicProvider {

    async get(key) {
        const data = await apiService.post('/non_alcoholic_fatty_liver_read', { non_alcoholic_fatty_liver_id: key });
        if (data && data.non_alcoholic_fatty_livers) {
            return new NonAlcoholic(data.non_alcoholic_fatty_livers);
        }
        return null;
    }

    async create(iin ) {
        const data = await apiService.post('/non_alcoholic_fatty_liver_create', { iin: iin  });
        if (data) {
            return data;
        }
        return null;
    }

}

const nonAlcoholicProvider = new NonAlcoholicProvider();
export default nonAlcoholicProvider;