import React from 'react';

const FieldSet = ({  children, ...rest  }) => {
    return (
        <fieldset className={"flex flex-col gap-4 "} {...rest}>
            {children}
        </fieldset>
    );
};

export default FieldSet;