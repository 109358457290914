import React, { useEffect, useState } from 'react';
import apiService from "../../../../provider/ApiService";
import PatientVisit from "../../../../models/PatientVisit";
import ItemVisitMenu from "./ItemVisitMenu";
import { useListVisitsData } from "../../../../contexts/ListVisitsContext";
import Patient from "../../../../models/Patient";
import patientVisitProvider from "../../../../provider/PatientVisitProvider";

const VisitEmpty = () => {
    return <div className={"color-gray-pa py-2.5 px-9 text-xs text-center border-b"}>
        Информация о приёмах отсутствует, проведите первый прём
    </div>
}

const ListVisits = () => {
    const { listVisit, setListVisit } = useListVisitsData();
    const [error, setError] = useState('');

    const fetchListVisit = async () => {
        try {
            const patientJson = localStorage.getItem("patient") ? JSON.parse(localStorage.getItem("patient")) : {};

            if (patientJson && patientJson.visits) {
                const patient = new Patient(patientJson);
                await patient.updateStorage();
                const visitsArray = [];
                let index = 0;
                const count = patientJson.visits.length;

                for (const visitKey of [...patientJson.visits].reverse()) {
                    const data = await patientVisitProvider.getVisitJson(visitKey);
                    if (data) {
                        const patientVisit = new PatientVisit(data);
                        await patientVisit.setPatient(patient);
                        const number = count - index ;

                        await patientVisit.setNumber(number);
                        visitsArray.push(patientVisit);
                        index++;
                    }
                }
                setListVisit(visitsArray);
            }
        } catch (err) {
            console.log(err);
            setError('Ошибка авторизации');
        }
    };

    useEffect(() => {
        if (!listVisit) {
            fetchListVisit();
        }
    }, [listVisit]);

    if (!listVisit || listVisit.length === 0) {
        return <VisitEmpty />;
    } else {
        return <div className={"menu-visits"}>
            {listVisit.map((visit, index) => {

                return (
                    <ItemVisitMenu
                        key={index} // Добавьте ключ
                        visit={visit}
                    />
                )
            })}
        </div>
    }
}

export default ListVisits;