import apiService from "./ApiService";
import PatientVisit from "../models/PatientVisit";

class PatientVisitProvider {

    async getVisitJson(visitKey) {
        const data = await apiService.post('/visit_read', { visit: visitKey });
        if (data && data.visits) {
            return data.visits;
        }
        return null;
    }

    async createPatientVisit(iin ) {
        const data = await apiService.post('/visit_create',
            {
                iin: iin
            });

        if (data && data.visits) {
            return data.visits;
        }
        return null;
    }
}


const patientVisitProvider = new PatientVisitProvider();
export default patientVisitProvider;