import apiService from "./ApiService";
import ONMK from "../models/ONMK";

class OnmkProvider {

    async get(key) {
        const data = await apiService.post('/onmk_read', { onmk_id: key });
        if (data && data.onmks) {
            return new ONMK(data.onmks);
        }
        return null;
    }

    async create(iin ) {
        const data = await apiService.post('/onmk_create', { iin: iin  });
        if (data) {
            return data;
        }
        return null;
    }

}

const onmkProvider = new OnmkProvider();
export default onmkProvider;