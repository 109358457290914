import FieldSetVisitForm from "../Part/FieldSetVisitForm";
import EditFieldVisitForm from "../Part/EditFieldVisitForm";

const GettingObjectsHelpQuestionsForm = ({visit}) => {

    return <FieldSetVisitForm title={"Помощь"} id={"base"}>
        <EditFieldVisitForm
            visit={visit}
            objectType={"visit"}
            fieldObject="haq_help2"
            typeFieldForm={"checkbox"}
            label={"Отметьте Требуется ли вам посторонняя помощь для выполнения описанных выше действий?"}
        />
    </FieldSetVisitForm>
}

export default GettingObjectsHelpQuestionsForm;