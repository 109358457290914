class ApiService {
    constructor(baseURL) {
        this.baseURL = baseURL;
    }

    baseUrl() {
        return this.baseURL;
    }

    async post(endpoint, params = {}) {
        params.key = localStorage.getItem("key");
        params.user_id = localStorage.getItem("user_id");

        const url = this.buildUrl(endpoint, params);
        const response = await fetch(url, { method: 'POST' }).catch(() => {

        });

        return this.handleResponse(response);
    }

    async upload(formData = {}) {
        const headers = {};
        const endpoint = '/upload';
        const params = {};
        params.key = localStorage.getItem("key");
        params.user_id = localStorage.getItem("user_id");

        const url = this.buildUrl(endpoint, params);

        const response = await fetch(url, {
            method: 'POST',
            body: formData,
            headers,
        }).catch(() => {
        });

        return this.handleResponse(response);
    }

    async get(endpoint, params = {}) {
        const url = this.buildUrl(endpoint, params);
        const response = await fetch(url, { method: 'GET' });

        return this.handleResponse(response);
    }

    buildUrl(endpoint, params) {
        const query = new URLSearchParams(params).toString();
        return `${this.baseURL}${endpoint}?${query}`;
    }

    async handleResponse(response) {
        if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
        }
        const responseJson = await response.json();
        if (responseJson.description && responseJson.description.trim() == "key not found" ) {
            localStorage.removeItem("key");
            localStorage.removeItem("user_id");
            window.location.reload();
        }
        return responseJson;
    }
}
const apiService = new ApiService('https://ra-api.medsystem.online');
export default apiService;