import apiService from "./ApiService";
import Analys from "../models/Analys";

class AnalysProvider {

    async getAnalys(analysKey) {
        const data = await apiService.post('/analys_read', { analys: analysKey });
        if (data && data.analyss) {
            return new Analys(data.analyss);
        }
        return null;
    }

    async createAnalyse(iin, visitId, bloodTestType) {
        const data = await apiService.post('/analys_create', { iin: iin, visit: visitId, blood_test_type: bloodTestType });
        if (data) {
            return data;
        }
        return null;
    }

}


const analysProvider = new AnalysProvider();
export default analysProvider;