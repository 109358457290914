import React from 'react';
import PropTypes from 'prop-types';

const Button = ({ label, type = 'button', size = 'medium', color = 'primary', fullWidth = false, icon = '', onClick, ...rest }) => {
    const sizeClass = {
        small: 'py-1 px-2 text-sm',
        medium: 'py-2 px-4 text-base',
        large: 'py-3 px-6 text-lg'
    };

    const colorClass = {
        primary: 'bg-green-pa text-white',
        secondary: 'bg-gray-500 text-white',
        lite: 'bg-lite-pa text-black border-gray-pa border transition',
        white: 'bg-white text-black border-gray-pa border transition hover:bg-slate-100',
        danger: 'border-red-pa border color-red-pa  '
    };

    const wFull = fullWidth ? 'w-full' : '';

    const iconType = {
        search: (<svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '<path d="M13.2125 12.0015L9.15469 7.94365C9.78438 7.12959 10.125 6.13428 10.125 5.0874C10.125 3.83428 9.63594 2.65928 8.75156 1.77334C7.86719 0.887402 6.68906 0.399902 5.4375 0.399902C4.18594 0.399902 3.00781 0.888965 2.12344 1.77334C1.2375 2.65771 0.75 3.83428 0.75 5.0874C0.75 6.33896 1.23906 7.51709 2.12344 8.40146C3.00781 9.2874 4.18438 9.7749 5.4375 9.7749C6.48438 9.7749 7.47813 9.43428 8.29219 8.80615L12.35 12.8624C12.3619 12.8743 12.376 12.8838 12.3916 12.8902C12.4071 12.8966 12.4238 12.9 12.4406 12.9C12.4575 12.9 12.4741 12.8966 12.4897 12.8902C12.5052 12.8838 12.5194 12.8743 12.5312 12.8624L13.2125 12.1827C13.2244 12.1708 13.2338 12.1567 13.2403 12.1411C13.2467 12.1256 13.2501 12.1089 13.2501 12.0921C13.2501 12.0753 13.2467 12.0586 13.2403 12.043C13.2338 12.0275 13.2244 12.0134 13.2125 12.0015V12.0015ZM7.9125 7.5624C7.25 8.22334 6.37187 8.5874 5.4375 8.5874C4.50313 8.5874 3.625 8.22334 2.9625 7.5624C2.30156 6.8999 1.9375 6.02178 1.9375 5.0874C1.9375 4.15303 2.30156 3.27334 2.9625 2.6124C3.625 1.95146 4.50313 1.5874 5.4375 1.5874C6.37187 1.5874 7.25156 1.9499 7.9125 2.6124C8.57344 3.2749 8.9375 4.15303 8.9375 5.0874C8.9375 6.02178 8.57344 6.90147 7.9125 7.5624Z" fill="white" fill-opacity="0.85"/>\n' +
            '</svg>)
    }

    return (
        <button
            type={type}
            className={`rounded ${sizeClass[size]} ${colorClass[color]} ${wFull}  flex gap-4 center justify-center items-center`}
            onClick={onClick}
            {...rest}
        >
            {icon ? iconType[icon] : ''}
            {label}
        </button>
    );
};

Button.propTypes = {
    label: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['button', 'submit', 'reset']),
    icon: PropTypes.oneOf(['search']),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    color: PropTypes.oneOf(['primary', 'secondary', 'danger']),
    onClick: PropTypes.func
};

export default Button;