import Button from "../../../../../Part/Form/Button";
import React, {useEffect, useState} from "react";
import EditFieldVisitForm from "../../Part/EditFieldVisitForm";
import fieldChangeProvider from "../../../../../../provider/FieldChangeProvider";
import DeleteButton from "../../../../../Part/Form/DeleteButton";
import anemiasProvider from "../../../../../../provider/AnemiasProvider";
import oncologyProvider from "../../../../../../provider/OncologyProvider";
import ihdProvider from "../../../../../../provider/IHDProvider";


const FieldSetForm = ({title, children}) => {
    return <div className={""}>
        <div className={"text-base mb-2"}>{title}</div>
        {children}
    </div>;
}


const EditForm = ({visit, itemKey, handleDelete }) => {

    const [objectEditor, setObjectEditor] = useState(null);


    useEffect( () => {

        const fetchObject = async (itemKey) => {
            const objectData = await ihdProvider.getIHD(itemKey);
            setObjectEditor(objectData);
        }

        fetchObject(itemKey) ;

    }, [visit, itemKey]);


    const yearOptions = [{name: "-", value: ""}];
    const year = new Date().getFullYear();
    for(let i = year; i > 1900; i-- ) {
        yearOptions.push({name: i, value: i});
    }

    if (objectEditor) {

        return <div className={"relative border bg-white p-5 flex flex-col  gap-5 "}>
            <div className={"absolute right-5 top-5"} >
                <DeleteButton
                    itemId={objectEditor.getId()}
                    onDelete={handleDelete}
                />
            </div>
            <FieldSetForm title={"Название"}>
                <EditFieldVisitForm visit={visit} typeFieldForm={"text"} objectType={"instance"} fieldObject={"name"} instance={objectEditor} placeholder={"Напишите название"}/>
            </FieldSetForm>
            <div className={"flex gap-2 items-center"}>
                <span className={"text-base"}>Год установления</span>
                <EditFieldVisitForm visit={visit} typeFieldForm={"select"} objectType={"instance"} fieldObject={"ihd_year"} instance={objectEditor} size={"small"} options={yearOptions} />
            </div>
            <FieldSetForm title={"Тип анемии"}>
                <EditFieldVisitForm visit={visit} typeFieldForm={"checkbox"} objectType={"instance"} fieldObject={"ihd_type"} instance={objectEditor} hasButtonNewItem={true} hasOneValue={true} />
            </FieldSetForm>
        </div>
    }

    return  <div></div>;
}

const IHDTreatmentVisitForm = ({visit}) => {

    const [listKeys, setListKeys ] = useState([]);

    useEffect(() => {
        const fetchKeys = async () => {
            let keys = await visit.getPatient().getValue("ihd");
            if (!keys) {
                keys = [];
            }
            setListKeys(keys);
        }

        fetchKeys() ;

    },[visit]);


    const handleAdd = async () => {
        const data = await ihdProvider.createIHD(visit.getPatient().getIIN() );

        if (data && data.ihd_id) {
            const listData = [...listKeys];
            listData.push(data.ihd_id);
            setListKeys(listData);
        }
    }

    const handleDelete = async (key) => {
        await fieldChangeProvider.setFieldIHD(key, "is_del", true , 1);
        const newList = listKeys.filter(item => item !== key);
        setListKeys(newList);
    }

    return  <div className={"flex gap-5 items-start"}>
            <div className={"w-60 text-sm"}>ИБС</div>
            <div className={" flex flex-col grow max-w-xl gap-3"}>
                {
                    listKeys.map((itemKey, index) => {
                        return <EditForm itemKey={itemKey} visit={visit} handleDelete={handleDelete}/>
                    })
                }
                <div className={"mt-2"}>
                    <Button color={"lite"} size={"small"} label={"Добавить"} onClick={handleAdd}/>
                </div>
            </div>
        </div>;

}

export default IHDTreatmentVisitForm;