
const CoefFisicalFieldVisitForm = ({title, coef, colorHex, text, valuesInfo = []}) => {

    const ColumnCoefValue = ({name, value}) => {
        return <div className={"py-3 px-4 flex gap-2.5 text-base items-center justify-center"}>
            <span className={"uppercase"}>{name}</span>
            <strong >{value}</strong>
        </div>
    }

    const InfoCoef = ({info}) => {
        return <div className={"text-base uppercase w-96"}>{info}</div>
    }

    return <div className={"w-full mt-5 border-b"}>
        <div className={"bg-lemon-pa flex items-center justify-center py-7 gap-7"}
             style={{
                 backgroundColor: colorHex
             }}>
            <div className={"text-2xl font-medium text-black uppercase"}>{title}</div>
            <div
                className={"rounded-full font-medium bg-white block w-10 h-10 flex items-center justify-center"}
                >{coef}</div>
            <InfoCoef info={text}/>
        </div>
        <div className={"bg-white grid grid-cols-4  "}>
            {
                valuesInfo.map((item) => {
                    return <ColumnCoefValue name={item.name} value={item.value}/>
                })
            }
        </div>
    </div>
}

export default CoefFisicalFieldVisitForm;