import apiService from "./ApiService";

class ImagesProvider {

    getImageUrl(fileName) {
        const key = localStorage.getItem("key");
        const userId = localStorage.getItem("user_id");

        return apiService.baseUrl()+`/download/${fileName}?user_id=${userId}&key=${key}`;
    }

    getImageUrlShort(fileName) {
        const key = localStorage.getItem("key");
        const userId = localStorage.getItem("user_id");
        return apiService.baseUrl()+`/download/short_${fileName}?user_id=${userId}&key=${key}`;
    }
}


const imagesProvider = new ImagesProvider();
export default imagesProvider;