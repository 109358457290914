import apiService from "./ApiService";

class FieldChangeProvider {

    async setFieldIIN(iinValue, field, value, quant = 1) {

        if (quant === true) {
            quant = 1;
        } else if (quant === false) {
            quant = 0;
        } else if (quant == null) {
            quant = 1;
        }

        let params = {
            peremtype: "iin",
            iin: iinValue,
            peremname: field,
            quant: quant
        };
        params[field] = value;

        const fieldSetFetch = await apiService.post('/edit', params);
        return fieldSetFetch;
    }

    async setFieldVisit(idValue, field, value, quant = 1) {

        if (quant === true) {
            quant = 1;
        } else if (quant === false) {
            quant = 0;
        }

        let params = {
            peremtype: "visit",
            visit: idValue,
            peremname: field,
            quant: quant
        };
        params[field] = value;

        const fieldSetFetch = await apiService.post('/edit', params);
        return fieldSetFetch;
    }

    async setFieldAnalys(idValue, field, value, quant = 1) {

        let params = {
            peremtype: "analys",
            analys: idValue,
            peremname: field,
            quant: quant
        };
        params[field] = value;

        const fieldSetFetch = await apiService.post('/edit', params);
        return fieldSetFetch;
    }

    async setFieldRadiography(idValue, field, value, quant = 1) {

        if (quant === true) {
            quant = 1;
        } else if (quant === false) {
            quant = 0;
        }

        let params = {
            peremtype: "radiography",
            radiography: idValue,
            peremname: field,
            quant: quant
        };

        params[field] = value;

        const fieldSetFetch = await apiService.post('/edit', params);
        return fieldSetFetch;
    }

    async setFieldFractures(idValue, field, value, quant = 1) {

        if (quant === true) {
            quant = 1;
        } else if (quant === false) {
            quant = 0;
        }

        let params = {
            peremtype: "fractures",
            fractures: idValue,
            peremname: field,
            quant: quant
        };
        params[field] = value;

        const fieldSetFetch = await apiService.post('/edit', params);
        return fieldSetFetch;
    }

    async setFieldTakeMedicine(idValue, field, value, quant = 1) {

        if (quant === true) {
            quant = 1;
        } else if (quant === false) {
            quant = 0;
        } else if (quant === null) {
            quant = 1;
        }

        let params = {
            peremtype: "takemedicine",
            takemedicine: idValue,
            peremname: field,
            quant: quant
        };
        params[field] = value;

        const fieldSetFetch = await apiService.post('/edit', params);
        return fieldSetFetch;
    }

    async setFieldAnemias(idValue, field, value, quant = 1) {

        if (quant === true) {
            quant = 1;
        } else if (quant === false) {
            quant = 0;
        }

        let params = {
            peremtype: "anemias",
            anemias: idValue,
            peremname: field,
            quant: quant
        };
        params[field] = value;

        const fieldSetFetch = await apiService.post('/edit', params);
        return fieldSetFetch;
    }

    async setFieldOncology(idValue, field, value, quant = 1) {

        if (quant === true) {
            quant = 1;
        } else if (quant === false) {
            quant = 0;
        }

        let params = {
            peremtype: "oncology",
            oncology: idValue,
            peremname: field,
            quant: quant
        };
        params[field] = value;

        const fieldSetFetch = await apiService.post('/edit', params);
        return fieldSetFetch;
    }

    async setFieldIHD(idValue, field, value, quant = 1) {

        if (quant === true) {
            quant = 1;
        } else if (quant === false) {
            quant = 0;
        }

        let params = {
            peremtype: "ihd",
            ihd: idValue,
            peremname: field,
            quant: quant
        };
        params[field] = value;

        const fieldSetFetch = await apiService.post('/edit', params);
        return fieldSetFetch;
    }

    async setFieldDiabet(idValue, field, value, quant = 1) {

        if (quant === true) {
            quant = 1;
        } else if (quant === false) {
            quant = 0;
        }

        let params = {
            peremtype: "diabetes_mellitus",
            diabetes_mellitus: idValue,
            peremname: field,
            quant: quant
        };
        params[field] = value;

        const fieldSetFetch = await apiService.post('/edit', params);
        return fieldSetFetch;
    }

    async setFieldONMK(idValue, field, value, quant = 1) {

        if (quant === true) {
            quant = 1;
        } else if (quant === false) {
            quant = 0;
        }

        let params = {
            peremtype: "onmk",
            onmk: idValue,
            peremname: field,
            quant: quant
        };
        params[field] = value;

        const fieldSetFetch = await apiService.post('/edit', params);
        return fieldSetFetch;
    }

    async setFieldTuberculosis(idValue, field, value, quant = 1) {

        if (quant === true) {
            quant = 1;
        } else if (quant === false) {
            quant = 0;
        }

        let params = {
            peremtype: "tuberculosis",
            tuberculosis: idValue,
            peremname: field,
            quant: quant
        };
        params[field] = value;

        const fieldSetFetch = await apiService.post('/edit', params);
        return fieldSetFetch;
    }

    async setFieldPregnancy(idValue, field, value, quant = 1) {

        if (quant === true) {
            quant = 1;
        } else if (quant === false) {
            quant = 0;
        }

        let params = {
            peremtype: "pregnancy",
            pregnancy: idValue,
            peremname: field,
            quant: quant
        };
        params[field] = value;

        const fieldSetFetch = await apiService.post('/edit', params);
        return fieldSetFetch;
    }

    async setFieldIZL(idValue, field, value, quant = 1) {

        if (quant === true) {
            quant = 1;
        } else if (quant === false) {
            quant = 0;
        }

        let params = {
            peremtype: "izl",
            izl: idValue,
            peremname: field,
            quant: quant
        };
        params[field] = value;

        const fieldSetFetch = await apiService.post('/edit', params);
        return fieldSetFetch;
    }

    async setFieldArterialHypertension(idValue, field, value, quant = 1) {

        if (quant === true) {
            quant = 1;
        } else if (quant === false) {
            quant = 0;
        }

        let params = {
            peremtype: "arterial_hypertension",
            arterial_hypertension: idValue,
            peremname: field,
            quant: quant
        };
        params[field] = value;

        const fieldSetFetch = await apiService.post('/edit', params);
        return fieldSetFetch;
    }

    async setFieldNonAlcoholic(idValue, field, value, quant = 1) {

        if (quant === true) {
            quant = 1;
        } else if (quant === false) {
            quant = 0;
        }

        let params = {
            peremtype: "non_alcoholic_fatty_liver",
            non_alcoholic_fatty_liver: idValue,
            peremname: field,
            quant: quant
        };
        params[field] = value;

        const fieldSetFetch = await apiService.post('/edit', params);
        return fieldSetFetch;
    }

    async setFieldHepatitisB(idValue, field, value, quant = 1) {

        if (quant === true) {
            quant = 1;
        } else if (quant === false) {
            quant = 0;
        }

        let params = {
            peremtype: "hepatitis_b",
            hepatitis_b: idValue,
            peremname: field,
            quant: quant
        };
        params[field] = value;

        const fieldSetFetch = await apiService.post('/edit', params);
        return fieldSetFetch;
    }

    async setFieldHepatitisC(idValue, field, value, quant = 1) {

        if (quant === true) {
            quant = 1;
        } else if (quant === false) {
            quant = 0;
        }

        let params = {
            peremtype: "hepatitis_c",
            hepatitis_c: idValue,
            peremname: field,
            quant: quant
        };
        params[field] = value;

        const fieldSetFetch = await apiService.post('/edit', params);
        return fieldSetFetch;
    }

    async setFieldSecondarySystemicAmyloidosis(idValue, field, value, quant = 1) {

        if (quant === true) {
            quant = 1;
        } else if (quant === false) {
            quant = 0;
        }

        let params = {
            peremtype: "secondary_systemic_amyloidosis",
            secondary_systemic_amyloidosis: idValue,
            peremname: field,
            quant: quant
        };
        params[field] = value;

        const fieldSetFetch = await apiService.post('/edit', params);
        return fieldSetFetch;
    }

    async setFieldSecondaryOsteoarthritis(idValue, field, value, quant = 1) {

        if (quant === true) {
            quant = 1;
        } else if (quant === false) {
            quant = 0;
        }

        let params = {
            peremtype: "secondary_osteoarthritis",
            secondary_osteoarthritis: idValue,
            peremname: field,
            quant: quant
        };
        params[field] = value;

        const fieldSetFetch = await apiService.post('/edit', params);
        return fieldSetFetch;
    }

    async setFieldTunnelSyndromes(idValue, field, value, quant = 1) {

        if (quant === true) {
            quant = 1;
        } else if (quant === false) {
            quant = 0;
        }

        let params = {
            peremtype: "tunnel_syndromes",
            tunnel_syndromes: idValue,
            peremname: field,
            quant: quant
        };
        params[field] = value;

        const fieldSetFetch = await apiService.post('/edit', params);
        return fieldSetFetch;
    }

    async setFieldSubluxation(idValue, field, value, quant = 1) {

        if (quant === true) {
            quant = 1;
        } else if (quant === false) {
            quant = 0;
        }

        let params = {
            peremtype: "subluxation",
            subluxation: idValue,
            peremname: field,
            quant: quant
        };
        params[field] = value;

        const fieldSetFetch = await apiService.post('/edit', params);
        return fieldSetFetch;
    }

    async setFieldOtherAssociatedConditions(idValue, field, value, quant = 1) {

        if (quant === true) {
            quant = 1;
        } else if (quant === false) {
            quant = 0;
        }

        let params = {
            peremtype: "other_associated_conditions",
            other_associated_conditions: idValue,
            peremname: field,
            quant: quant
        };
        params[field] = value;

        const fieldSetFetch = await apiService.post('/edit', params);
        return fieldSetFetch;
    }
}


const fieldChangeProvider = new FieldChangeProvider();
export default fieldChangeProvider;