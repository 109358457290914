import apiService from "./ApiService";
import IZL from "../models/IZL";
import ArterialHypertension from "../models/ArterialHypertension";

class ArterialHypertensionProvider {

    async get(key) {
        const data = await apiService.post('/arterial_hypertension_read', { arterial_hypertension_id: key });
        if (data && data.arterial_hypertensions) {
            return new ArterialHypertension(data.arterial_hypertensions);
        }
        return null;
    }

    async create(iin ) {
        const data = await apiService.post('/arterial_hypertension_create', { iin: iin  });
        if (data) {
            return data;
        }
        return null;
    }

}

const arterialHypertensionProvider = new ArterialHypertensionProvider();
export default arterialHypertensionProvider;