import TitlePartVisit from "./Part/TitlePartVisit";
import FieldSetVisitForm from "./Part/FieldSetVisitForm";
import BaseIdentificationForm from "./Identification/BaseIdentificationForm";
import SocialIdentificationForm from "./Identification/SocialIdentificationForm";
import DisabilityIdentificationForm from "./Identification/DisabilityIdentificationForm";
import InvalidnostIdentificationForm from "./Identification/InvalidnostIdentificationForm";
import PrivichkiIdentificationForm from "./Identification/PrivichkiIdentificationForm";

const IdentificationPatientVisit = ({visit}) => {

    return <div className={" py-10 px-10  border-b"}>
        <TitlePartVisit title={"Идентификация"}/>
        <BaseIdentificationForm visit={visit}/>
        <SocialIdentificationForm visit={visit}/>
        <DisabilityIdentificationForm visit={visit}/>
        <InvalidnostIdentificationForm visit={visit}/>
        <PrivichkiIdentificationForm visit={visit}/>
    </div>
}

export default IdentificationPatientVisit;