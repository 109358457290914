import React from 'react';
import PropTypes from 'prop-types';

const Radio = ({ name, options, value, onChange = () => {}, ...rest }) => {

    return (
        <div className="radio-group flex gap-3">
            {options.map((option, index) => (
                <label key={index} className="radio-option  ">
                    <input
                        type="radio"
                        name={name} // Добавляем имя, если нужно
                        value={option.value}
                        checked={value === String(option.value)}
                        onChange={() => onChange(name, option.value)}
                        className="mr-2"
                        {...rest}
                    />
                    {option.name}
                </label>
            ))}
        </div>
    );
};

Radio.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func,
};

export default Radio;