import FieldSetVisitForm from "../Part/FieldSetVisitForm";
import EditFieldVisitForm from "../Part/EditFieldVisitForm";
import {getValue} from "@testing-library/user-event/dist/utils";
import {useState} from "react";

const InvalidnostIdentificationForm = ({visit}) => {
    const [disabilityReason, setDisabilityReason] = useState(visit.getValue("disability_reason"));

    const booleanOptions = [{name: "Нет", value: "0"}, {name: "Да", value: "1"}];
    const groupOptions = [{name: "1", value: "1"}, {name: "2", value: "2"}, {name: "3", value: "3"}];
    const disabilityReasonOptions = [{name: "PA", value: "ra"}, {name: "Другое", value: ""}];

    const handleDisabilityChange = (value) => {
        setDisabilityReason(value);
    }

    return <FieldSetVisitForm title={"Инвалидоность"} id={"invalidnost"}>
        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="is_disability" typeFieldForm={"boolean"} label={"Наличие инвалидности"} options={booleanOptions} />
        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="disability_group" typeFieldForm={"radio"} label={"Группа инвалидности"} options={groupOptions} />
        <EditFieldVisitForm
            visit={visit}
            objectType={"visit"}
            fieldNameStart={"disability_year_start"}
            fieldNameEnd={"disability_year_end"}
            fieldObject="disability_year"
            typeFieldForm={"period"}
            label={"Год установления инвалидности:"}
        />
        {( disabilityReason == "ra"
            ?
                <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="disability_reason" typeFieldForm={"boolean"} label={"Причина инвалидности"} options={disabilityReasonOptions} onChange={handleDisabilityChange} />
            :
                <div className={"flex flex-col gap-4"}>
                    <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="disability_reason" typeFieldForm={"boolean"} label={"Причина инвалидности "} options={disabilityReasonOptions} value={""} onChange={handleDisabilityChange}/>
                    <div className={"ml-60 pl-5"}>
                        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="disability_reason" typeFieldForm={"text"} label={""} value={disabilityReason} width={"w-80"}/>
                    </div>
                </div>
            )
        }



    </FieldSetVisitForm>
}

export default InvalidnostIdentificationForm;