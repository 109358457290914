import FieldSetVisitForm from "../Part/FieldSetVisitForm";
import EditFieldVisitForm from "../Part/EditFieldVisitForm";

const WalkingAidsQuestionsForm = ({visit}) => {

    return <FieldSetVisitForm title={"Вспомогательные средства"} id={"base"}>
        <EditFieldVisitForm
            visit={visit}
            objectType={"visit"}
            fieldObject="subsidiary1"
            typeFieldForm={"checkbox"}
            label={"Используете ли вы какие-либо из указанных Вспомогательных средст или приспособлений для выполнения описанных выше действий?"}
            hasButtonNewItem={true}
        />
    </FieldSetVisitForm>
}

export default WalkingAidsQuestionsForm;