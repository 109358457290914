import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Form from "../../../../Part/Form/Form";
import Row from "../../../../Part/Form/Row";
import FieldSet from "../../../../Part/Form/FieldSet";
import Input from "../../../../Part/Form/Input";
import Textarea from "../../../../Part/Form/Textarea";
import Radio from "../../../../Part/Form/Radio";
import Checkbox from "../../../../Part/Form/Checkbox";
import InputRange from "../../../../Part/Form/InputRange";
import Select from "../../../../Part/Form/Select";
import Boolean from "../../../../Part/Form/Boolean";
import Period from "../../../../Part/Form/Period";
import dateProvider from "../../../../../provider/DateProvider";
import File from "../../../../Part/Form/File";
import PeriodMonth from "../../../../Part/Form/PeriodMonth";

const EditFieldVisitForm = ({ visit = null, objectType, fieldObject, typeFieldForm, label = null, placeholder, iin = null, analys = null, radiography = null, fractures = null, takeMedicine= null, instance = null, onChange = (value) => {}, ...rest }) => {
    const [value, setValue] = useState(null);
    const [options, setOptions] = useState([]);
    const [object, setObject] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            let object = null;
            if (objectType === 'iin') {
                object = iin;
            } else if (objectType === 'visit') {
                object = visit;
            } else if (objectType === 'analys') {
                object = analys;
            } else if (objectType === 'radiography') {
                object = radiography;
            } else if (objectType === 'fractures') {
                object = fractures;
            } else if (objectType === 'takeMedicine') {
                object = takeMedicine;
            } else if (objectType === 'anemias') {
                object = instance;
            }  else if (objectType === 'instance') {
                object = instance;
            }

            setObject(object);
            const value = await object.getValue(fieldObject);
            const options = await object.getOptions(fieldObject);
            setValue(value);
            setOptions(options || []);
        };

        fetchData();
    },  [visit, objectType, fieldObject, analys, radiography, fractures, takeMedicine, instance]);

    const handleChange = async (key, value, quant = null) => {
        setValue(value);
        const result = await object.setValue(key, value, quant, true);
        onChange(value);
        return result;
    };

    const renderInputField = () => {
        switch (typeFieldForm) {
            case 'text':
                return <Input name={fieldObject} value={value} placeholder={placeholder} onChange={handleChange}  {...rest}/>;
            case 'file':
                return <File name={fieldObject} objectType={objectType} value={value} placeholder={placeholder} onChange={handleChange}  {...rest}/>;
            case 'textarea':
                return <Textarea name={fieldObject} value={value} placeholder={placeholder} onChange={handleChange}  {...rest}/>;
            case 'select':
                return <Select name={fieldObject} value={value} options={options} placeholder={placeholder} onChange={handleChange} {...rest} />;
            case 'radio':
                return <Radio name={fieldObject} options={options} value={value} onChange={handleChange}  {...rest}/>;
            case 'checkbox':
                return <Checkbox name={fieldObject} options={options} value={value} onChange={handleChange}  {...rest}/>;
            case 'boolean':
                return <Boolean name={fieldObject} options={options} value={value} onChange={handleChange}  {...rest}/>;
            case 'range':
                return <InputRange name={fieldObject} value={value} onChange={handleChange} {...rest}/>;
            case 'period':
                const { fieldNameStart, fieldNameEnd } = rest;
                const valueStart = object.getValue(fieldNameStart);
                const valueEnd = object.getValue(fieldNameEnd);
                return <Period name={fieldObject}  onChange={handleChange} fieldNameStart={fieldNameStart} valueStart={valueStart} fieldNameEnd={fieldNameStart} valueEnd={valueEnd} {...rest}/>;
            case 'periodMonth':
                const { fieldYearStart, fieldYearEnd } = rest;
                const { fieldMonthStart, fieldMonthEnd } = rest;
                const valueYearStart = object.getValue(fieldYearStart);
                const valueYearEnd = object.getValue(fieldYearEnd);
                const valueMonthStart = object.getValue(fieldMonthStart);
                const valueMonthEnd = object.getValue(fieldMonthEnd);

                return <PeriodMonth
                    name={fieldObject}
                    onChange={handleChange}
                    fieldYearStart={valueYearStart}
                    fieldYearEnd={fieldYearEnd}
                    fieldMonthStart={fieldMonthStart}
                    fieldMonthEnd={fieldMonthEnd}
                    valueYearStart={valueYearStart}
                    valueYearEnd={valueYearEnd}
                    valueMonthStart={valueMonthStart}
                    valueMonthEnd={valueMonthEnd}
                    {...rest}/>;

            case 'date':
                let dateValue = value;
                if (value && !isNaN(value) && Number.isInteger(Number(value))) {
                    dateValue = dateProvider.strToDateInput(value);
                } else {
                    dateValue = '';
                }
                return <Input type={"date"} name={fieldObject} value={dateValue} placeholder={placeholder} close={false} onChange={handleChange}  {...rest}/>;
            case 'timer':
                const { fieldNameHours, fieldNameMinutes } = rest;
                const valueHours = object.getValue(fieldNameHours);
                const valueMinutes = object.getValue(fieldNameMinutes);

                const hoursOptions = [];
                for(let i = 0; i < 24; i++) {
                    hoursOptions.push({name: i + " ч.", value: i});
                }
                const minOptions = [];
                for(let i = 0; i < 60; i++) {
                    minOptions.push({name: i+ " мин.", value: i});
                }
                return <div className={"flex gap-2"}>
                    <Select name={fieldNameHours} value={valueHours} options={hoursOptions} onChange={handleChange} width={"w-20"} {...rest}  />
                    <Select name={fieldNameMinutes} value={valueMinutes} options={minOptions} placeholder={placeholder} onChange={handleChange} width={"w-20"} {...rest} />
                </div>;
                return <Input type={"time"} name={fieldObject} value={value} placeholder={placeholder} onChange={handleChange}  {...rest}/>;
            default:
                return <Input value={""} placeholder={placeholder} onChange={handleChange}/>;
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Отменяем сабмит
        }
    };

    return (object  &&
        <Form action={"edit"}  onKeyDown={handleKeyDown}  >
            {label ?
                <FieldSet>
                    <Row label={label} name={fieldObject}
                         style={{
                             'align-items': typeFieldForm === "checkbox" ? "flex-start" : "",
                         }} >
                        {renderInputField()}
                    </Row>
                </FieldSet>
                :
                renderInputField()
            }
        </Form>
    );
}

EditFieldVisitForm.propTypes = {
    objectType: PropTypes.oneOf(['iin', 'visit', 'analys', 'radiography', 'fractures', 'anemias']),
    typeFieldForm: PropTypes.oneOf(['text', 'radio', 'range', 'select', 'date', 'textarea', 'boolean', 'file'])
};

export default EditFieldVisitForm;