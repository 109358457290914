// src/App.js
import React, { useState, useEffect } from 'react';
import Login from './components/Auth/Login';
import Cabinet from './components/Layouts/Cabinet';
import { Fancybox } from '@fancyapps/ui';
import packageJson from '../package.json';


const App = () => {
  const [key, setKey] = useState(null);
  const [userId, setUserId] = useState(null);


    useEffect(() => {
        // Check for token in localStorage on initial load
        const keyStorage = localStorage.getItem('key');
        const userIdStorage = localStorage.getItem('user_id');
        if (keyStorage) {
            setKey(keyStorage);
        }
        if (userIdStorage) {
            setUserId(userIdStorage);
        }

        Fancybox.bind("[data-modal]", {
            hideScrollbar: false,
            loop: false,
            parentEl: document.getElementById("root")
        });

    }, []);


  return (
          <div className={"box-container box-border relative"}>
            {!key || !userId ? (
                <Login setUserId={setUserId} setKey={setKey}/>
            ) : (
                <Cabinet/>
            )}
              <div className={"absolute bottom-0 left-0 p-2 text-neutral-300 text-sm"}>{packageJson.version}</div>
          </div>
  );
};

export default App;