import TitlePartVisit from "./Part/TitlePartVisit";
import FieldSetVisitForm from "./Part/FieldSetVisitForm";
import EditFieldVisitForm from "./Part/EditFieldVisitForm";
import {useState} from "react";

const AboutDiseasePatientVisit = ({visit}) => {
    const [accpValue, setAccpValue] = useState(visit.getValue("accp"));

    const seropositivityOptions = [{name: "Серопозитивный", value: "1"},{name: "Серонегативный", value: "0"}];
    const accpOptions = [{name: "Исследован", value: "1"},{name: "Не исследован", value: "0"}];
    const functionalClassOptions = [{name: "0 класс", value: "0"},{name: "I класс", value: "1"},{name: "II класс", value: "2"},{name: "III класс", value: "3"}];

    const handleAccpChange = (value) => {
        setAccpValue(value);
    }

    const AccpFieldVisitForm = () => {
        return <div className={"flex flex-col gap-4"}>
            <EditFieldVisitForm  visit={visit} objectType={"visit"} fieldObject="accp_index" typeFieldForm={"text"} label={"Показатель"} width={"w-40"} />
            <EditFieldVisitForm  visit={visit} objectType={"visit"} fieldObject="research_time" typeFieldForm={"date"} label={"Дата исследования"} width={"76px"} />
        </div>
    }

    return <div className={" py-10 px-10  border-b"}>
        <TitlePartVisit title={"Сведения о заболевании НА МОМЕНТ РЕГИСТРАЦИИ"}/>
        <FieldSetVisitForm  id={"about"}>
            <EditFieldVisitForm  visit={visit} objectType={"visit"} fieldObject="diagnosis" typeFieldForm={"checkbox"} label={"Наличие РА "}  width={"w-96"} multiselect/>
            <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="seropositivity" typeFieldForm={"radio"} label={"Серопозитивность по РФ"}  options={seropositivityOptions}/>
            <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="clinical_stage" typeFieldForm={"radio"} label={"Клиническая стадия"}   />
            <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="erosiveness" typeFieldForm={"radio"} label={"Эрозивность"}   />
            <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="accp" typeFieldForm={"radio"} label={"АЦЦП"}  options={accpOptions} onChange={handleAccpChange}/>
            {
                accpValue == "1" && <AccpFieldVisitForm/>
            }
            <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="functional_class" typeFieldForm={"radio"} label={"Функциональный класс"}  options={functionalClassOptions} />
            <EditFieldVisitForm  visit={visit} objectType={"visit"} fieldObject="date_of_diagnosis_ra_time" typeFieldForm={"date"} label={"Дата постановки диагноза РА"}  />
            <EditFieldVisitForm  visit={visit} objectType={"visit"} fieldObject="date_of_start_ra_time" typeFieldForm={"date"} label={"Дата начала РА"}  />
        </FieldSetVisitForm>
    </div>
}

export default AboutDiseasePatientVisit;