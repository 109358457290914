import apiService from "./ApiService";
import Oncology from "../models/Oncology";

class OncologyProvider {

    async getOncology(key) {
        const data = await apiService.post('/oncology_read', { oncology_id: key });
        if (data && data.oncologys) {
            return new Oncology(data.oncologys);
        }
        return null;
    }

    async createOncology(iin ) {
        const data = await apiService.post('/oncology_create', { iin: iin  });
        if (data) {
            return data;
        }
        return null;
    }

}

const oncologyProvider = new OncologyProvider();
export default oncologyProvider;