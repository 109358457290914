import FieldSetVisitForm from "../Part/FieldSetVisitForm";
import EditFieldVisitForm from "../Part/EditFieldVisitForm";

const DressingQuestionsForm = ({visit}) => {

    const options = [{name: "Без труда", value: "Без труда"},{name: "С некоторым затруднением", value: "С некоторым затруднением"},{name: "С большим трудом", value: "С большим трудом"},{name: "Не могу выполнить", value: "Не могу выполнить"}];
    return <FieldSetVisitForm title={"Одевание и уход за собой"} id={"base"}>
        <EditFieldVisitForm
            visit={visit}
            objectType={"visit"}
            fieldObject="get_dresses"
            typeFieldForm={"radio"}
            label={"В состоянии ли вы одеться, в том числе зашнуровать ботинки и застегнуть пуговицы?"}
            options={options}
        />
        <EditFieldVisitForm
            visit={visit}
            objectType={"visit"}
            fieldObject="wash_hair"
            typeFieldForm={"radio"}
            label={"В состоянии ли вы вымыть себе голову?"}
            options={options}
        />
    </FieldSetVisitForm>
}

export default DressingQuestionsForm;