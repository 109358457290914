
import React, {useState} from 'react';
import {Link, useLocation} from "react-router-dom";

const ItemVisitMenu = ({visit}) => {

    const location = useLocation();
    const href = `/patient/visit/${visit.getId()}`;
    const active= location.pathname === href;
    return <Link to={href} className={'menu-visit block px-5 py-2.5 border-b ' + (active ? 'menu-visit_active' : '')}>
        <div className={"flex justify-between w-full"}>
            <div className={"menu-item__label uppercase overflow-hidden font-bold text-sm grow  "}>Прием №{visit.getNumber()}</div>
            <div className={"text-xs color-gray-dark-pa"}>{visit.getAppearTime()}</div>
        </div>
        <div className={"text-xs color-gray-pa leading-5 mt-1.5 mb-0.5"}>
            Принимающий врач
        </div>
        <div className={"text-sm  uppercase  leading-none"}>
            {visit.getValue('doctor_fio')}
        </div>
    </Link>
}

export default ItemVisitMenu;