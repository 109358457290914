
import React, {useState} from 'react';

const PatientFirstVisitSidebar = () => {

    return <div className={'  border-r bg-white w-60 min-h-screen'}>
        первичный визит пациента
    </div>
}

export default PatientFirstVisitSidebar;