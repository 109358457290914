import React, {useEffect, useState} from 'react';
import Input from "../../Part/Form/Input";
import Button from "../../Part/Form/Button";
import Title from "../../Part/Form/Title";
import apiService from "../../../provider/ApiService";
import {useSearchParams} from "react-router-dom";

const SearchPatientsForm = ({ setKey, setUserId }) => {

    const [searchParams] = useSearchParams();
    const iinParam = searchParams.get('iin');

    const [iin, setIIN] = useState(iinParam);
    const [error, setError] = useState('');
;


    return (
        <form   className="flex gap-4 pl-5" method={"get"} action={"/search"}>
            <div className={"input-search-iin"}>
                <Input name={"iin"} value={iin}   placeholder={"ИИН пациента"} required />
            </div>
            <div className={"w-32 "}>
                <Button type={"submit"} label={"Найти"} icon={"search"}/>
            </div>
            {error && <p className="mt-2 text-red-500">{error}</p>}
        </form>

    );
};

export default SearchPatientsForm;