import FieldSetVisitForm from "../Part/FieldSetVisitForm";
import EditFieldVisitForm from "../Part/EditFieldVisitForm";

const WalkingQuestionsForm = ({visit}) => {

    const options = [{name: "Без труда", value: "Без труда"},{name: "С некоторым затруднением", value: "С некоторым затруднением"},{name: "С большим трудом", value: "С большим трудом"},{name: "Не могу выполнить", value: "Не могу выполнить"}];
    return <FieldSetVisitForm title={"Ходьба"} id={"base"}>
        <EditFieldVisitForm
            visit={visit}
            objectType={"visit"}
            fieldObject="walk_on_level_ground"
            typeFieldForm={"radio"}
            label={"В состоянии ли вы ходить по ровной местности вне дома?"}
            options={options}
        />
        <EditFieldVisitForm
            visit={visit}
            objectType={"visit"}
            fieldObject="climb_5_steps"
            typeFieldForm={"radio"}
            label={"В состоянии ли вы подняться по лестнице на 5 ступенек?"}
            options={options}
        />
    </FieldSetVisitForm>
}

export default WalkingQuestionsForm;