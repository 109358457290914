import FieldSetVisitForm from "../Part/FieldSetVisitForm";
import EditFieldVisitForm from "../Part/EditFieldVisitForm";

const GettingObjectsQuestionsForm = ({visit}) => {

    const options = [{name: "Без труда", value: "Без труда"},{name: "С некоторым затруднением", value: "С некоторым затруднением"},{name: "С большим трудом", value: "С большим трудом"},{name: "Не могу выполнить", value: "Не могу выполнить"}];
    return <FieldSetVisitForm title={"Доставание предметов"} id={"base"}>
        <EditFieldVisitForm
            visit={visit}
            objectType={"visit"}
            fieldObject="reach_above_head"
            typeFieldForm={"radio"}
            label={"В состоянии ли вы достать с высоты чуть выше головы и опустить вниз предмет весом 2 кг (например, пакет с мукой)?"}
            options={options}
        />
        <EditFieldVisitForm
            visit={visit}
            objectType={"visit"}
            fieldObject="pick_up_from_the_floor"
            typeFieldForm={"radio"}
            label={"В состоянии ли вы наклониться вниз, чтобы поднять одежду с пола?"}
            options={options}
        />
    </FieldSetVisitForm>
}

export default GettingObjectsQuestionsForm;