import apiService from "./ApiService";
import Analys from "../models/Analys";
import Radiography from "../models/Radiography";

class RadiographyProvider {

    async getRadiography(radiographyKey) {
        const data = await apiService.post('/radiography_read', { radiography: radiographyKey });
        if (data && data.radiographys) {
            return new Radiography(data.radiographys);
        }
        return null;
    }

    async createRadiography(iin, visitId) {
        const data = await apiService.post('/radiography_create', { iin: iin, visit_id: visitId });
        if (data) {
            return data;
        }
        return null;
    }

}


const radiographyProvider = new RadiographyProvider();
export default radiographyProvider;