
import React, {useState} from 'react';
import { useParams } from 'react-router-dom';

const PatientNewVisitContent = () => {

    const { id } = useParams();
    return <div>
        <p className={"uppercase text-xl max-w-sm font-bold leading-7 px-8 py-5"}>
            Добавляем прием пациента
        </p>
    </div>
}

export default PatientNewVisitContent;