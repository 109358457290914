import apiService from "./ApiService";
import HepatitisB from "../models/HepatitisB";
import HepatitisC from "../models/HepatitisC";

class HepatitisCProvider {

    async get(key) {
        const data = await apiService.post('/hepatitis_c_read', { hepatitis_c_id: key });
        if (data && data.hepatitis_cs) {
            return new HepatitisC(data.hepatitis_cs);
        }
        return null;
    }

    async create(iin ) {
        const data = await apiService.post('/hepatitis_c_create', { iin: iin  });
        if (data) {
            return data;
        }
        return null;
    }

}

const hepatitisCProvider = new HepatitisCProvider();
export default hepatitisCProvider;