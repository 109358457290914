import DatePatientVisit from "./DatePatientVisit";
import React from "react";

const HeaderPatientInfoVisit = ({visit}) => {

    return <div className={"flex justify-between py-3 px-10 bg-white border-b"}>
        <div className={"flex justify-between gap-3"}>
            <svg width="24" height="27" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.3333 3.00016H15.76C15.2 1.4535 13.7333 0.333496 12 0.333496C10.2667 0.333496 8.8 1.4535 8.24 3.00016H2.66667C1.2 3.00016 0 4.20016 0 5.66683V24.3335C0 25.8002 1.2 27.0002 2.66667 27.0002H21.3333C22.8 27.0002 24 25.8002 24 24.3335V5.66683C24 4.20016 22.8 3.00016 21.3333 3.00016ZM12 3.00016C12.7333 3.00016 13.3333 3.60016 13.3333 4.3335C13.3333 5.06683 12.7333 5.66683 12 5.66683C11.2667 5.66683 10.6667 5.06683 10.6667 4.3335C10.6667 3.60016 11.2667 3.00016 12 3.00016ZM12 8.3335C14.2133 8.3335 16 10.1202 16 12.3335C16 14.5468 14.2133 16.3335 12 16.3335C9.78667 16.3335 8 14.5468 8 12.3335C8 10.1202 9.78667 8.3335 12 8.3335ZM20 24.3335H4V22.4668C4 19.8002 9.33333 18.3335 12 18.3335C14.6667 18.3335 20 19.8002 20 22.4668V24.3335Z" fill="#5CBB7A"/>
            </svg>
            <span className={"uppercase text-balck text-2xl font-bold"}>{visit.getPatient().getFIO()}</span>
        </div>
        <div className={"flex gap-10 items-center"}>
            <span>ИИН {visit.getPatient().getIIN()}</span>
            <span className={"flex gap-2 items-center"}>
                <span>
                    {visit.getPatient().getBirthday()}
                </span>
                <span>
                    ({visit.getPatient().getAge()} лет)
                </span>
                <DatePatientVisit visit={visit}/>
            </span>
        </div>
    </div>
}

export default HeaderPatientInfoVisit;