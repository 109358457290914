import React from 'react';

const Form = ({  action,children, ...rest  }) => {
    return (
        <form className={"flex flex-col gap-4 "} action={action} {...rest}>
            {children}
        </form>
    );
};

export default Form;