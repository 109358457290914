import EditFieldVisitForm from "../Part/EditFieldVisitForm";
import {useEffect, useState} from "react";
import analysProvider from "../../../../../provider/AnalysProvider";
import AnalysResultTable from "./AnalysResultTable";
import Button from "../../../../Part/Form/Button";
import DeleteButton from "../../../../Part/Form/DeleteButton";
import fieldChangeProvider from "../../../../../provider/FieldChangeProvider";

const AnalysPatientVisitForm = ({visit, analysKey,  commonbloodTest, biochemicalTest}) => {
    const [analys, setAnalys] = useState(null);
    const [results, setResults] = useState([]);

    useEffect(  () =>  {
        const fetchData = async () => {
            const analysData = await analysProvider.getAnalys(analysKey);
            setAnalys(analysData);
            const results = await analysData.getValue("results");
            setResults(results);
        }

        fetchData();

    }, [analysKey]);


    if (analys) {

        const title = analys.isCommonBlood() ? "Общий анализ крови" : "Биохимический анализ крови";
        const indicatorsTest = analys.isCommonBlood() ? commonbloodTest : biochemicalTest;

        return <div className={"flex flex-col gap-4"}>

            <div className={"flex gap-5 items-center"}>
                <EditFieldVisitForm  visit={visit} analys={analys} objectType={"analys"} fieldObject="appear_time" typeFieldForm={"date"} label={"Дата анализа крови "}  />

            </div>
            <AnalysResultTable analys={analys} results={results } title={title} indicatorsTest={indicatorsTest}  />
        </div>
    }

    return <div></div>;
}

export default AnalysPatientVisitForm;