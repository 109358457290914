import React from "react";

const HeaderAvatar = () => {
    const fio = localStorage.getItem("fio");

    return <a className={"flex items-center gap-1 cursor-pointer"}  data-modal  data-src="#user-info-modal">
        <div className={"rounded-full border-4 overflow-hidden w-10 h-10  flex items-center justify-center"}>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="40" height="40" fill="#EFEFEF"/>
                <path d="M20.0004 21.4393C21.3492 21.4393 22.5958 21.0592 23.5931 20.4134C24.3657 19.9188 25.3711 20.0006 26.0496 20.6178C27.9339 22.3263 29.0048 24.7501 29.0007 27.2965V28.3878C29.0007 29.5159 28.0851 30.4274 26.957 30.4274H13.0437C11.9156 30.4274 11 29.5159 11 28.3878V27.2965C10.9919 24.7542 12.0628 22.3263 13.947 20.6219C14.6255 20.0047 15.6351 19.9229 16.4035 20.4175C17.4049 21.0592 18.6475 21.4393 20.0004 21.4393Z" fill="#B5BBDB"/>
                <path d="M19.9976 19.6203C22.7923 19.6203 25.0578 17.3548 25.0578 14.5601C25.0578 11.7655 22.7923 9.5 19.9976 9.5C17.203 9.5 14.9375 11.7655 14.9375 14.5601C14.9375 17.3548 17.203 19.6203 19.9976 19.6203Z" fill="#B5BBDB"/>
            </svg>
        </div>

        <span>{fio}</span>

    </a>
}

export default HeaderAvatar;