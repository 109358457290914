import directoryProvider from "../provider/DirectoryProvider";
import fieldChangeProvider from "../provider/FieldChangeProvider";
import imagesProvider from "../provider/ImagesProvider";
import dateProvider from "../provider/DateProvider";
import DateProvider from "../provider/DateProvider";

class Radiography {
    constructor(data) {
        this.data = data;
    }

    getId() {
        return this.data["radiography_id"] ?? "";
    }

    getValue(key) {
        return this.data[key];
    }

    async setValue(key, value, quant = null, isServerChange = false) {
        this.data[key] = value;
        if (isServerChange) {
            const setServer = await fieldChangeProvider.setFieldRadiography(this.getId(), key, value, quant);
            if (!setServer) {
                console.log("Error! Save Server");
            }
        }
    }

    getImages() {
        const pictures = this.getValue("pictures");

        const listImage = [];
        if (pictures) {

            pictures.map(async (fileName) => {
                listImage.push(imagesProvider.getImageUrl(fileName));
            });
        }

        return listImage;
    }

    async getOptions(fieldObject) {
        const directoryList = [ ];
        if (directoryList.indexOf(fieldObject) !== -1) {
            const directory = await directoryProvider.getDirectory(fieldObject);
            return directory;
        }
        return [];
    }

    getAppearTime() {
        const appearTime = this.getValue("appear_time");
        if (appearTime && !isNaN(appearTime) && Number.isInteger(Number(appearTime))) {
            return  DateProvider.strToDateInput(appearTime);
        }
        return appearTime;
    }
}


export default Radiography;