import {useHelp} from "../../../provider/HelpProvider";

const HeaderInfo = () => {

    const help = useHelp();

    if (help.r_alarm) {
        document.documentElement.style.setProperty('--height-info', '41px');
    }


    return <div>
        {
             help.r_alarm && <div className={"alarm-bg-pa bg-yellow-500 w-full border-b text-center "}>
                <span className={"alarm-text-pa bg-yellow-500 py-2 px-10 inline-block rounded-lg font-medium"}>{help.r_alarm}</span>
            </div>
        }
        { help.r_information && <div className={"w-full  border-b text-center p-2"} >
                 {help.r_information}
            </div>
        }
    </div>
}


export default HeaderInfo