
import React, {useState} from 'react';
import {Link} from "react-router-dom";
import patientProvider from "../../../../provider/PatientProvider";
import patientVisitProvider from "../../../../provider/PatientVisitProvider";

const NewVisitButton = ({iin, label}) => {


    const handleCreateVisit = async () => {
        try {
            const result = await patientVisitProvider.createPatientVisit(iin);
            if (result) {
                const href = `/patient/visit/${result.visit_id}`;
                window.location = href;
            } else {
            }
        } catch (err) {
        }
    };

    const svgIcon = <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 0.25C7.91421 0.25 8.25 0.585786 8.25 1V6.25H13.5C13.9142 6.25 14.25 6.58579 14.25 7C14.25 7.41421 13.9142 7.75 13.5 7.75H8.25V13C8.25 13.4142 7.91421 13.75 7.5 13.75C7.08579 13.75 6.75 13.4142 6.75 13V7.75H1.5C1.08579 7.75 0.75 7.41421 0.75 7C0.75 6.58579 1.08579 6.25 1.5 6.25H6.75V1C6.75 0.585786 7.08579 0.25 7.5 0.25Z" fill="white"/>
    </svg>;

    return <Link to={"#"} onClick={handleCreateVisit} className={'menu-button px-5 py-1 flex gap-1.5 justify-center items-center '}>
        <div className={" flex items-center justify-center w-6 h-6"}>{svgIcon}</div>
        <div className={"menu-item__label uppercase overflow-hidden font-bold text-sm  "}>{label}</div>
    </Link>
}

export default NewVisitButton;