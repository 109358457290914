import TitlePartVisit from "./Part/TitlePartVisit";
import MedicinesInspectionForm from "./Inspection/MedicinesInspectionForm";

const InspectionPatientVisit = ({visit}) => {

    return <div className={" py-10 px-10  border-b"}>
        <TitlePartVisit title={"СВЕДЕНИЯ О ЛЕЧЕНИИ ЗАБОЛЕВАНИЯ"}/>
        <MedicinesInspectionForm visit={visit}/>

    </div>
}

export default InspectionPatientVisit;