import apiService from "./ApiService";
import IZL from "../models/IZL";
import SecondaryOsteoarthritis from "../models/SecondaryOsteoarthritis";

class SecondaryOsteoarthritisProvider {

    async get(key) {
        const data = await apiService.post('/secondary_osteoarthritis_read', { secondary_osteoarthritis_id: key });
        if (data && data.secondary_osteoarthritiss) {
            return new SecondaryOsteoarthritis(data.secondary_osteoarthritiss);
        }
        return null;
    }

    async create(iin ) {
        const data = await apiService.post('/secondary_osteoarthritis_create', { iin: iin  });
        if (data) {
            return data;
        }
        return null;
    }

}

const secondaryOsteoarthritisProvider = new SecondaryOsteoarthritisProvider();
export default secondaryOsteoarthritisProvider;