import React from 'react';
import ReactDOM from 'react-dom';
import '@fancyapps/ui/dist/fancybox/fancybox.css';
import { Fancybox } from '@fancyapps/ui';
import Button from "./Button";

const ConfirmDelete = ({ onConfirm, onCancel, message }) => {
    return (
        <div>
            <p>{message}</p>
            <div className={"flex gap-5 mt-5"}>
                <Button color={"secondary"} onClick={onCancel} label={"Нет"} fullWidth={true}/>
                <Button color={"danger"} onClick={onConfirm} label={"Да"}  fullWidth={true}/>
            </div>
        </div>
    );
};


const DeleteButton = ({ itemId, onDelete, message = "Вы действительно хотите удалить объект?", textButton = "", sizeButton = "small", colorButton = "danger" }) => {

    const handleDelete = () => {
        const container = document.createElement('div');

        Fancybox.show([
            {
                src: container,
                type: 'html',
            },
        ]);

        ReactDOM.render(
            <ConfirmDelete
                onConfirm={() => {
                    onDelete(itemId);
                    Fancybox.close();
                }}
                onCancel={() => {
                    Fancybox.close();
                }}
                message={message}
            />,
            container
        );
    };

    if (textButton != "") {
        return <div className={"cursor-pointer"} onClick={handleDelete}>
            <Button label={textButton} color={colorButton} size={sizeButton}/>
        </div>;
    } else {
        return <div className={"cursor-pointer"} onClick={handleDelete}>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.92472 6.08217L11.6122 0.494671C11.6908 0.401814 11.6247 0.260742 11.5033 0.260742H10.0783C9.99437 0.260742 9.91401 0.298242 9.85865 0.362528L5.99258 4.97146L2.12651 0.362528C2.07294 0.298242 1.99258 0.260742 1.90687 0.260742H0.481867C0.360439 0.260742 0.294367 0.401814 0.372939 0.494671L5.06044 6.08217L0.372939 11.6697C0.355338 11.6904 0.344047 11.7157 0.340404 11.7426C0.336762 11.7695 0.340922 11.7969 0.352391 11.8216C0.36386 11.8462 0.382156 11.867 0.405107 11.8816C0.428057 11.8961 0.454698 11.9037 0.481867 11.9036H1.90687C1.9908 11.9036 2.07115 11.8661 2.12651 11.8018L5.99258 7.19289L9.85865 11.8018C9.91222 11.8661 9.99258 11.9036 10.0783 11.9036H11.5033C11.6247 11.9036 11.6908 11.7625 11.6122 11.6697L6.92472 6.08217Z" fill="black" fill-opacity="0.45"></path></svg>
        </div>;
    }

};

export default DeleteButton;