import apiService from "../provider/ApiService";
import dateProvider from "../provider/DateProvider";
import fieldChangeProvider from "../provider/FieldChangeProvider";
import directoryProvider from "../provider/DirectoryProvider";

class PatientVisit {
    constructor(data) {
        this.data = data;
    }

    // Метод для получения значения по ключу
    getValue(key) {
        return this.data[key];
    }

    // Метод для установки значения по ключу
    async setValue(key, value, quant = null, isServerChange = false) {
        this.data[key] = value;
        if (isServerChange) {
            const response = await fieldChangeProvider.setFieldVisit(this.getId(), key, value, quant);
            if (!response) {
                console.log("Error! Save Server");
            }
            return response;
        }
        return value;
    }

    async getOptions(fieldObject) {
        const directoryList = [
            "gender",
            "diagnosis",
            "race",
            "education",
            "employment",
            "smoking",
            "clinical_stage",
            "erosiveness",
            "complaint",
            "brushes",
            "knee_joints",
            "feet",
            "hip_joints",
            "shoulder",
            "elbow_joints",
            "other_joints",
            "cardiovascular_system",
            "lungs",
            "skin",
            "nervous_system",
            "muscles",
            "eyes",
            "kidneys",
            "anemia_type",
            "chd_type",
            "diabetes_mellitus_type",
            "tuberculosis_type",
            "related_conditions",
            "complications",
            "subsidiary1",
            "haq_help1",
            "subsidiary2",
            "haq_help2",
            "constitutional_symptoms"
        ];
        const notes = this.getValue(fieldObject+"_notes");
        if ( directoryList.indexOf(fieldObject) !== -1 ) {
            let directory = await directoryProvider.getDirectory(fieldObject);

            if ( notes ) {
                const notesArray = Object.entries(notes);
                notesArray.forEach(item => {
                  directory.push({name: item[1], value: item[0], isDelete: true});
                });

            }

            return directory;
        }

        return [];
    }

    getId() {
        return this.data["visit_id"] ?? "";
    }

    getAppearTime() {
        const appearTime = this.data["appear_time"];
        return dateProvider.strToDate(appearTime);
    }

    setNumber(number) {
        return this.setValue("number", number);
    }

    getNumber() {
        return this.getValue("number");
    }


    setPatient(patient) {
        return this.data["patient"] = patient;
    }

    getPatient() {
        return this.getValue("patient");
    }

    getAnalyses() {
        return this.getValue("analyses");
    }

    async getAnalys(idAnalys) {

        return this.getValue("analyses");
    }

}


export default PatientVisit;