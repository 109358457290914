import apiService from "./ApiService";

class ReportProvider {

    getReportUrl(iin, reportName ) {
        const key = localStorage.getItem("key");
        const userId = localStorage.getItem("user_id");

        return apiService.baseUrl()+`/getreport?user_id=${userId}&key=${key}&iin=${iin}&report=${reportName}`;
    }

}


const reportProvider = new ReportProvider();
export default reportProvider;