
class DateProvider {

    strToDate(strTime) {
        const timestamp = parseInt(strTime); // UNIX timestamp в секундах
        const date = new Date(timestamp * 1000); // Преобразуем в миллисекунды

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}.${month}.${day}`;
    }

    strToDateInput(strTime) {
        const dateObject = new Date(Number(strTime) * 1000);
        return dateObject.toISOString().split('T')[0];
    }

    dateToTimestamp(dateValue) {
        return Math.floor(new Date(dateValue).getTime() / 1000);
    }

    timestampToDate(timestamp) {
        const date = new Date(timestamp * 1000);
        return date.toISOString().split('T')[0];
    }

}
const dateProvider = new DateProvider();
export default dateProvider;