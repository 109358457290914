
import React, {useEffect, useState} from 'react';
import apiService from "../../../provider/ApiService";
import {BrowserRouter as Router, useSearchParams } from 'react-router-dom';
import PatientItem from "./Search/PatientItem";

const SearchContent = () => {
    const [searchParams] = useSearchParams();
    const iin = searchParams.get('iin');

    const [patient, setPatient] = useState('');
    const [isSearch, setIsSearch] = useState(true);

    const [error, setError] = useState('');

    const fetchSearch = async () => {

        try {
            setError("");
            const data = await apiService.post('/iin_read', { iin });
            setIsSearch(false);
            if (data.ok && data.iins ) {
                setPatient(data.iins);
                localStorage.setItem("patient", JSON.stringify(data.iins));
            } else {
                console.log(data.description)
                setError("Номер не найден!");
            }

        } catch (err) {
            console.log(err)
            setError('Произошла ошибка');
        }
    };

    useEffect(() => {
        fetchSearch();
    }, []);

    return <div>
        {
            isSearch ?
                (<p className={"uppercase text-xl max-w-sm font-bold leading-7 px-8 py-5 gap-4"}>Поиск...</p>)
            :
                patient ?
                    <PatientItem patient={patient}/>
                :
                    <p className={"uppercase text-xl max-w-sm font-bold leading-7 px-8 py-5 gap-4"}>Ничего не найдено</p>
        }
    </div>
}

export default SearchContent;