
import React, {useEffect, useState} from 'react';
import Input from "../../Part/Form/Input";
import Button from "../../Part/Form/Button";
import apiService from "../../../provider/ApiService";
import HeaderAvatar from "./HeaderAvatar";
import UserInfoModal from "./UserInfoModal";
import SearchPatientsForm from "../Forms/SearchPatientsForm";
import {Link} from "react-router-dom";
import CreatePatientModalForm from "../Forms/CreatePatientModalForm";

const HeaderLine = () => {
    const [error, setError] = useState('');
    const [isScrolled, setIsScrolled] = useState(false);

    const [fio, setFio] = useState( localStorage.getItem("fio"));
    const [jobTitle, setJobTitle] = useState(localStorage.getItem("job_title"));


    const fetchUserInfo = async (setFio, setJobTitle) => {
        try {

            const key = localStorage.getItem('key');
            const userId = localStorage.getItem('user_id' );
            const data = await apiService.post('/user_read', { key: key, user_id :userId });

            if (data.users && data.users.fio) {
                localStorage.setItem('fio', data.users.fio);
                setFio(data.users.fio);
            }
            if (data.users && data.users.job_title) {
                localStorage.setItem('job_title', data.users.job_title);
                setJobTitle(data.users.job_title);
            }

        } catch (err) {
            console.log(err);
            setError('Ошибка авторизации');
        }
    };

    useEffect(() => {
        fetchUserInfo(setFio, setJobTitle);
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 0);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className={`flex gap-4 border-b bg-white ${isScrolled ? 'sticky-header' : ''}`}>
            <Link to={"/"} className={"font-bold text-sm color-green-pa uppercase py-2.5 pl-5 w-60"}>
                Реестр больных ревматоидным артритом
            </Link>
            <div className={"flex grow justify-between py-2.5 pr-10"}>
                <SearchPatientsForm/>
                <div className={"flex gap-4"}>
                    <Button label={"Создать пациента"} data-modal data-src="#create-patient-modal" />
                    <CreatePatientModalForm/>
                    <UserInfoModal />
                    <HeaderAvatar/>
                </div>
            </div>
        </div>
    );
}

export default HeaderLine;