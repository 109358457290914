import React, { useState } from 'react';
import Button from "../Part/Form/Button";
import Input from "../Part/Form/Input";
import Title from "../Part/Form/Title";
import apiService from "../../provider/ApiService";

const Login = ({ setKey, setUserId }) => {
    const [identifier, setIdentifier] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const data = await apiService.post('/input_identifier', { identifier });
            if (data.identifiers.key && data.identifiers.user_id) {
                localStorage.setItem('key', data.identifiers.key);
                localStorage.setItem('user_id', data.identifiers.user_id);
                setKey(data.identifiers.key);
                setUserId(data.identifiers.user_id);
            } else {
                setError("Номер не найден");
            }

        } catch (err) {
            console.log(err);
            setError('Ошибка авторизации');
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-pa">
            <div className="flex flex-col w-full max-w-2xl gap-2  ">
                <Title label={"Вход в кабинет врача"} />
                <form onSubmit={handleSubmit} className="w-full p-7 bg-white shadow-md rounded-md">
                    <div className="flex items-center   gap-4	">
                        <Input type={"text"} size={"medium"} required onChange={(name, value) => {setIdentifier(value)}} value={identifier} placeholder={"Введите идентификатор"} borderColor={"gray"}/>
                        <Button label={"Войти"} type={"submit"} color="primary" size={"medium"} />
                    </div>
                    {error && <p className="mt-2 text-red-500">{error}</p>}
                </form>
            </div>
        </div>
    );
};

export default Login;