import apiService from "./ApiService";
import IZL from "../models/IZL";
import SecondarySystemicAmyloidosis from "../models/SecondarySystemicAmyloidosis";

class SecondarySystemicAmyloidosisProvider {

    async get(key) {
        const data = await apiService.post('/secondary_systemic_amyloidosis_read', { secondary_systemic_amyloidosis_id: key });
        if (data && data.secondary_systemic_amyloidosiss) {
            return new SecondarySystemicAmyloidosis(data.secondary_systemic_amyloidosiss);
        }
        return null;
    }

    async create(iin ) {
        const data = await apiService.post('/secondary_systemic_amyloidosis_create', { iin: iin  });
        if (data) {
            return data;
        }
        return null;
    }

}

const secondarySystemicAmyloidosisProvider = new SecondarySystemicAmyloidosisProvider();
export default secondarySystemicAmyloidosisProvider;