import TitlePartVisit from "./Part/TitlePartVisit";
import SymptomsTreatmentForm from "./Treatment/SymptomsTreatmentForm";
import SustavTreatmentForm from "./Treatment/SustavTreatmentForm";
import FisicalTreatmentForm from "./Treatment/FisicalTreatmentForm";
import VnesustavTreatmentForm from "./Treatment/VnesustavTreatmentForm";
import SoputstvuyshieTreatmentForm from "./Treatment/SoputstvuyshieTreatmentForm";

const TreatmentPatientVisit = ({visit}) => {

    return <div className={" py-10 px-10  border-b"}>
        <TitlePartVisit title={"Осмотр пациента"}/>
        <SymptomsTreatmentForm visit={visit}/>
        <FisicalTreatmentForm visit={visit}/>
        <SustavTreatmentForm visit={visit}/>
        <VnesustavTreatmentForm visit={visit}/>
        <SoputstvuyshieTreatmentForm visit={visit}/>
    </div>
}

export default TreatmentPatientVisit;