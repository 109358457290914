import React from 'react';
import PropTypes from 'prop-types';

const PeriodMonth = ({ name, valueYearStart, valueYearEnd, valueMonthStart, valueMonthEnd, fieldYearStart, fieldYearEnd, fieldMonthStart, fieldMonthEnd, onChange = () => {}, ...rest }) => {

    const handleChange = (e, name) => {
        onChange(name, e.target.value);
    };

    const optionsYear = [{name: "-", value: ""}];

    const year = new Date().getFullYear();
    for(let i = year; i > 1900; i-- ) {
        optionsYear.push({name: i, value: i});
    }
    const optionsMonth = [
        { name: "-", value: "" },
        { name: "Январь", value: 0 },
        { name: "Февраль", value: 1 },
        { name: "Март", value: 2 },
        { name: "Апрель", value: 3 },
        { name: "Май", value: 4 },
        { name: "Июнь", value: 5 },
        { name: "Июль", value: 6 },
        { name: "Август", value: 7 },
        { name: "Сентябрь", value: 8 },
        { name: "Октябрь", value: 9 },
        { name: "Ноябрь", value: 10 },
        { name: "Декабрь", value: 11 }
    ];


    return (
        <div className={"flex items-center justify-start gap-4"}>
            <select name={fieldMonthStart} className={"select-pa w-32 p-2 text-base border border-gray-300 rounded-sm focus:outline-none focus:border-green-500"} onChange={(e) => {handleChange(e, fieldMonthStart)}} {...rest}>
                {optionsMonth.map((option, index) => (
                    <option value={option.value} selected={valueMonthStart === String(option.value)}>{option.name}</option>
                ))}
            </select>
            <select name={fieldYearStart} className={"select-pa w-24 p-2 text-base border border-gray-300 rounded-sm focus:outline-none focus:border-green-500"} onChange={(e) => {handleChange(e, fieldYearStart)}} {...rest}>
            {optionsYear.map((option, index) => (
                <option value={option.value} selected={valueYearStart === String(option.value)}>{option.name}</option>
            ))}
            </select>
            <svg width="24" height="2" viewBox="0 0 24 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="0.503906" width="24" height="1" rx="0.5" fill="#D9D9D9"/>
            </svg>
            <select name={fieldMonthEnd} className={"select-pa w-32 p-2 text-base border border-gray-300 rounded-sm focus:outline-none focus:border-green-500"} onChange={(e) => {handleChange(e, fieldMonthEnd)}} {...rest}>
                {optionsMonth.map((option, index) => (
                    <option value={option.value} selected={valueMonthEnd === String(option.value)}>{option.name}</option>
                ))}
            </select>
            <select name={fieldYearEnd} className={"select-pa w-24 p-2 text-base border border-gray-300 rounded-sm focus:outline-none focus:border-green-500"} onChange={(e) => {handleChange(e, fieldYearEnd)}} {...rest}>
                {optionsYear.map((option, index) => (
                    <option value={option.value} selected={valueYearEnd === String(option.value)}>{option.name}</option>
                ))}
            </select>
        </div>
    );
};

PeriodMonth.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func,
};

export default PeriodMonth;