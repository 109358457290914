import FieldSetVisitForm from "../Part/FieldSetVisitForm";
import EditFieldVisitForm from "../Part/EditFieldVisitForm";

const PersonalHygieneQuestionsForm = ({visit}) => {

    const options = [{name: "Без труда", value: "Без труда"},{name: "С некоторым затруднением", value: "С некоторым затруднением"},{name: "С большим трудом", value: "С большим трудом"},{name: "Не могу выполнить", value: "Не могу выполнить"}];
    return <FieldSetVisitForm title={"Личная гигиена"} id={"base"}>
        <EditFieldVisitForm
            visit={visit}
            objectType={"visit"}
            fieldObject="completely_wash"
            typeFieldForm={"radio"}
            label={"В состоянии ли вы полностью вымыться и вытереться?"}
            options={options}
        />
        <EditFieldVisitForm
            visit={visit}
            objectType={"visit"}
            fieldObject="take_a_bath"
            typeFieldForm={"radio"}
            label={"В состоянии ли вы принять ванну?"}
            options={options}
        />
        <EditFieldVisitForm
            visit={visit}
            objectType={"visit"}
            fieldObject="sit_on_the_toilet"
            typeFieldForm={"radio"}
            label={"В состоянии ли вы сесть на унитаз и встать с унитаза?"}
            options={options}
        />
    </FieldSetVisitForm>
}

export default PersonalHygieneQuestionsForm;