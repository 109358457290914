import apiService from "./ApiService";

class UploadFileProvider {

    async uploadFile(formData) {
        return await apiService.upload(formData);
    }

}

const uploadFileProvider = new UploadFileProvider();
export default uploadFileProvider;