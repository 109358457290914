import EditFieldVisitForm from "../Visit/Part/EditFieldVisitForm";
import React, {useEffect, useState} from "react";

const DocFields = ({iin}) => {
    const [isFile, setIsFile] = useState(false);

    useEffect(() => {
        const fetchValue = async () => {
            const consentReceived = await iin.getValue("consent_received");
            if (consentReceived == "true") {
                setIsFile(true);
            }
        }
        fetchValue();
    }, [iin]);

    const handleDocChange = (value) => {
        setIsFile(value === "true");
    }


    const booleanOptions = [{name: "Нет", value: "false"}, {name: "Да", value: "true"}];
    return <div className={"flex  gap-4 "}>
            <EditFieldVisitForm
                objectType={"iin"}
                iin={iin}
                typeFieldForm={"boolean"}
                fieldObject={"consent_received"}
                label={"Информированное согласие пациента получено"}
                onChange={handleDocChange}
                options={booleanOptions}/>
            <EditFieldVisitForm
                objectType={"iin"}
                iin={iin}
                typeFieldForm={"file"}
                fieldObject={"consent_file"}
                label={""}
                idObject={iin.getIIN()}
                placeholder={""}
                />
    </div>

}

export default DocFields;