import directoryProvider from "../provider/DirectoryProvider";
import fieldChangeProvider from "../provider/FieldChangeProvider";
import imagesProvider from "../provider/ImagesProvider";

class Fractures {
    constructor(data) {
        this.data = data;
    }

    getId() {
        return this.data["fractures_id"] ?? "";
    }

    getValue(key) {
        return this.data[key];
    }

    async setValue(key, value, quant = null, isServerChange = false) {
        this.data[key] = value;
        if (isServerChange) {
            const setServer = await fieldChangeProvider.setFieldFractures(this.getId(), key, value, quant);
            if (!setServer) {
                console.log("Error! Save Server");
            }
        }
    }


    async getOptions(fieldObject) {
        const directoryList = [ ];
        if (directoryList.indexOf(fieldObject) !== -1) {
            const directory = await directoryProvider.getDirectory(fieldObject);
            return directory;
        }
        return [];
    }

}


export default Fractures;