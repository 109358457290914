import React from 'react';
import PropTypes from 'prop-types';

const Period = ({ name, valueStart, valueEnd, fieldNameStart, fieldNameEnd, onChange = () => {}, ...rest }) => {

    const handleChange = (e, name) => {
        onChange(name, e.target.value);
    };

    const optionsYear = [{name: "-", value: ""}];

    const year = new Date().getFullYear();
    for(let i = year; i > 1900; i-- ) {
        optionsYear.push({name: i, value: i});
    }


    return (
        <div className={"flex items-center justify-start gap-4"}>
            <select name={fieldNameStart} className={"select-pa w-24 p-2 text-base border border-gray-300 rounded-sm focus:outline-none focus:border-green-500"} onChange={(e) => {handleChange(e, fieldNameStart)}} {...rest}>
            {optionsYear.map((option, index) => (
                <option value={option.value} selected={valueStart === String(option.value)}>{option.name}</option>
            ))}
            </select>
            <svg width="24" height="2" viewBox="0 0 24 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="0.503906" width="24" height="1" rx="0.5" fill="#D9D9D9"/>
            </svg>
            <select name={fieldNameEnd} className={"select-pa w-24 p-2 text-base border border-gray-300 rounded-sm focus:outline-none focus:border-green-500"} onChange={(e) => {handleChange(e, fieldNameEnd)}} {...rest}>
                {optionsYear.map((option, index) => (
                    <option value={option.value} selected={valueEnd === String(option.value)}>{option.name}</option>
                ))}
            </select>
        </div>
    );
};

Period.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func,
};

export default Period;