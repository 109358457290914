

const PatientFirstVisitContent = () => {

    return <div>
        <p className={"uppercase text-xl max-w-sm font-bold leading-7"}>
            Первый визит
        </p>
    </div>
}

export default PatientFirstVisitContent;