import apiService from "./ApiService";
import Analys from "../models/Analys";
import Radiography from "../models/Radiography";
import Fractures from "../models/Fractures";

class FracturesProvider {

    async getFractures(fracturesKey) {
        const data = await apiService.post('/fractures_read', { fractures_id: fracturesKey });
        if (data && data.fracturess) {
            return new Fractures(data.fracturess);
        }
        return null;
    }

    async createFractures(iin, visitId) {
        const data = await apiService.post('/fractures_create', { iin: iin, visit_id: visitId });
        if (data) {
            return data;
        }
        return null;
    }


}


const fracturesProvider = new FracturesProvider();
export default fracturesProvider;