import FieldSetVisitForm from "../Part/FieldSetVisitForm";
import EditFieldVisitForm from "../Part/EditFieldVisitForm";

const EatingQuestionsForm = ({visit}) => {

    const options = [{name: "Без труда", value: "Без труда"},{name: "С некоторым затруднением", value: "С некоторым затруднением"},{name: "С большим трудом", value: "С большим трудом"},{name: "Не могу выполнить", value: "Не могу выполнить"}];
    return <FieldSetVisitForm title={"Прием пищи"} id={"base"}>
        <EditFieldVisitForm
            visit={visit}
            objectType={"visit"}
            fieldObject="cut_meat"
            typeFieldForm={"radio"}
            label={"В состоянии ли вы нарезать мясо на куски"}
            options={options}
        />
        <EditFieldVisitForm
            visit={visit}
            objectType={"visit"}
            fieldObject="lift_a_full_cup"
            typeFieldForm={"radio"}
            label={"В состоянии ли вы поднести ко рту полную чашку или стакан?"}
            options={options}
        />
        <EditFieldVisitForm
            visit={visit}
            objectType={"visit"}
            fieldObject="open_a_carton"
            typeFieldForm={"radio"}
            label={"В состоянии ли вы вскрыть картонную упаковку молока или кефира?"}
            options={options}
        />
    </FieldSetVisitForm>
}

export default EatingQuestionsForm;