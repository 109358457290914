import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import { useListVisitsData } from "../../../contexts/ListVisitsContext";
import HeaderPatientInfoVisit from "./Visit/HeaderPatientInfoVisit";
import IdentificationPatientVisit from "./Visit/IdentificationPatientVisit";
import AboutDiseasePatientVisit from "./Visit/AboutDiseasePatientVisit";
import AnalisisPatientVisit from "./Visit/AnalisisPatientVisit";
import RengenPatientVisit from "./Visit/RengenPatientVisit";
import TreatmentPatientVisit from "./Visit/TreatmentPatientVisit";
import InspectionPatientVisit from "./Visit/InspectionPatientVisit";
import QuestionsPatientVisit from "./Visit/QuestionsPatientVisit";
import SaveButtonPatientVisit from "./Visit/SaveButtonPatientVisit";
import EditFieldVisitForm from "./Visit/Part/EditFieldVisitForm";
import DatePatientVisit from "./Visit/DatePatientVisit";

const PatientVisitContent = () => {

    const { listVisit } = useListVisitsData();
    const { id } = useParams();
    let visit = null;

    if (listVisit) {
        visit = listVisit.find(visitItem => visitItem.getId() === id);
    }

    if (!visit) {
        return <div></div>;
    }

    return (
        <div>
            <HeaderPatientInfoVisit visit={visit}/>
            <IdentificationPatientVisit visit={visit}/>
            <AboutDiseasePatientVisit visit={visit}/>
            <AnalisisPatientVisit visit={visit}/>
            <RengenPatientVisit visit={visit}/>
            <TreatmentPatientVisit visit={visit}/>
            <InspectionPatientVisit visit={visit}/>
            <QuestionsPatientVisit visit={visit}/>
            <SaveButtonPatientVisit visit={visit}/>
        </div>
    );

}

export default PatientVisitContent;