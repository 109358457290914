import directoryProvider from "../provider/DirectoryProvider";
import fieldChangeProvider from "../provider/FieldChangeProvider";
import imagesProvider from "../provider/ImagesProvider";

class TakeMedicine {
    constructor(data) {
        this.data = data;
    }

    getId() {
        return this.data["takemedicine"] ?? "";
    }

    getValue(key) {
        return this.data[key];
    }

    async setValue(key, value, quant = null, isServerChange = false) {
        this.data[key] = value;
        if (isServerChange) {
            const setServer = await fieldChangeProvider.setFieldTakeMedicine(this.getId(), key, value, quant);
            if (!setServer) {
                console.log("Error! Save Server");
            }
        }
    }

    async getOptions(fieldObject) {
        const directoryList = ["ways_of_use", "reasons_for_drug_withdrawal", "measurement" ];
        if (directoryList.indexOf(fieldObject) !== -1) {
            const directory = await directoryProvider.getDirectory(fieldObject);
            return directory;
        }
        return [];
    }

    async getOptionValue(name) {
        const options = await directoryProvider.getDirectory(name);
        const value = await this.getValue(name);
        let result = "";
        if (value instanceof Array) {
            result = options.filter(d => value.includes(d.value)).map(d => d.name).join(', ');
        } else {
            result = options.filter(d => value.includes(d.value)).map(d => d.name).join(', ');
        }
        return result;
    }

    async getShortTitle() {
        let result = [];
        const dosageText = await this.getValue("dosage_text");
        const waysOfUse = await this.getOptionValue("ways_of_use");
        let measurement = await this.getOptionValue("measurement");
        const reception = await this.getValue("frequency_of_reception");


        if (measurement) {

            if (dosageText) {
                measurement = dosageText+measurement;
            }
            result.push(measurement);
        }
        if (reception) {
            result.push(reception.slice(0, 5)) ;
        }
        if (waysOfUse) {
            result.push(waysOfUse.slice(0, 3));
        }

        return result.join("/");

        //return this.getValue("dosage_text").slice(0, 3)+measurement+"/"+this.getValue("frequency_of_reception").slice(0, 5)+"/"+waysOfUse.slice(0, 3)

    }

}


export default TakeMedicine;