import directoryProvider from "../provider/DirectoryProvider";
import fieldChangeProvider from "../provider/FieldChangeProvider";

class Analys {
    constructor(data) {
        this.data = data;
    }

    getValue(key) {
        return this.data[key];
    }

    getId() {
        return this.data["analys_id"] ?? "";
    }

    isCommonBlood() {
        return (this.getValue("blood_test_type") === "blood_test_type_1");
    }
    isBiochemical() {
        return (this.getValue("blood_test_type") === "blood_test_type_2");
    }
    async getResultItemById(id) {
        if (this.isCommonBlood()) {

            let directory = this.getValue("commonblood_test_directory");
            if (!directory) {
                directory = await this.getOptions("commonblood_test");
                this.setValue("commonblood_test_directory", directory);
            };

            let resultItem = directory.find(resultItem => resultItem.value === id);
            return resultItem;

        } else if (this.isBiochemical()) {

            let directory = this.getValue("biochemical_test_directory");
            if (!directory) {
                directory = await this.getOptions("biochemical_test");
                this.setValue("biochemical_test_directory", directory);
            };

            let resultItem = directory.find(resultItem => resultItem.value === id);
            return resultItem;
        }
    }

    async setValue(key, value, quant = null, isServerChange = false) {
        this.data[key] = value;
        if (isServerChange) {
            const setServer = await fieldChangeProvider.setFieldAnalys(this.getId(), key, value, quant);
            if (!setServer) {
                console.log("Error! Save Server");
            }
        }
    }

    async getOptions(fieldObject) {
        const directoryList = [ "commonblood_test", "biochemical_test"  ];
        if (directoryList.indexOf(fieldObject) !== -1) {
            const directory = await directoryProvider.getDirectory(fieldObject);
            return directory;
        }
        return [];
    }

}


export default Analys;