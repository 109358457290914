import apiService from "./ApiService";
import HepatitisB from "../models/HepatitisB";

class HepatitisBProvider {

    async get(key) {
        const data = await apiService.post('/hepatitis_b_read', { hepatitis_b_id: key });
        if (data && data.hepatitis_bs) {
            return new HepatitisB(data.hepatitis_bs);
        }
        return null;
    }

    async create(iin ) {
        const data = await apiService.post('/hepatitis_b_create', { iin: iin  });
        if (data) {
            return data;
        }
        return null;
    }

}

const hepatitisBProvider = new HepatitisBProvider();
export default hepatitisBProvider;