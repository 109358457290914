import apiService from "./ApiService";

class DirectoryProvider {
    constructor() {
        this.cache = new Map(); // Используем Map для кэширования
    }

    async getDirectory(name) {
        // Проверяем, есть ли данные в кэше
        if (this.cache.has(name)) {
            return this.cache.get(name);
        }
        // Если нет, отправляем запрос
        const directory = await apiService.get('/shema', { dict_name: name });
        if (directory && directory.dicts) {
            this.cache.set(name, directory.dicts); // Сохраняем в кэше
            return directory.dicts;
        }

        return [];
    }
}

const directoryProvider = new DirectoryProvider();
export default directoryProvider;