import apiService from "./ApiService";
import PatientVisit from "../models/Patient";
import Patient from "../models/Patient";

class PatientProvider {

    async getPatient(iin) {
        const data = await apiService.post('/iin_read', { iin });
        if (data.ok && data.iins ) {
            return new Patient(data.iins);
        }
        return null;
    }
    async getVisitJson(visitKey) {
        const data = await apiService.post('/visit_read', { visit: visitKey });
        if (data && data.visits) {
            return data.visits;
        }
        return null;
    }

    async createPatient(iin, fio, date) {
        const data = await apiService.post('/iin_create',
            {
                iin: iin,
                birthday_time: date,
                fio: fio
            });

        if (data && data.iins) {
            return data.iins;
        }
        return null;
    }

}


const patientProvider = new PatientProvider();
export default patientProvider;