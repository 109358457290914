import TitlePartVisit from "./Part/TitlePartVisit";
import FieldSetVisitForm from "./Part/FieldSetVisitForm";
import BaseIdentificationForm from "./Identification/BaseIdentificationForm";
import SocialIdentificationForm from "./Identification/SocialIdentificationForm";
import DisabilityIdentificationForm from "./Identification/DisabilityIdentificationForm";
import InvalidnostIdentificationForm from "./Identification/InvalidnostIdentificationForm";
import PrivichkiIdentificationForm from "./Identification/PrivichkiIdentificationForm";
import {useState} from "react";
import AnalysResultItemTable from "./Analisis/AnalysResultItemTable";
import RadiographyVisitForm from "./Radiography/RadiographyVisitForm";
import Button from "../../../Part/Form/Button";
import radiographyProvider from "../../../../provider/RadiographyProvider";

const RengenPatientVisit = ({visit}) => {

    const [listRadiography, setListRadiography ] = useState(visit.getPatient().getValue("radiography"));
    const [openFormId, setOpenFormId] = useState("");

    const handleAddRengen = async () => {
        const data = await radiographyProvider.createRadiography(visit.getPatient().getIIN(), visit.getId());

        if (data && data.radiography_id) {
            const listRadiographyData = [...listRadiography];
            listRadiographyData.push(data.radiography_id);
            setListRadiography(listRadiographyData);
            setOpenFormId(data.radiography_id);
        }
    }

    const handleDelete = async (idRadiography) => {
        setListRadiography([...listRadiography.filter(item => item !== idRadiography)]);
    }

    return <div className={" py-10 px-10  border-b"}>
                <TitlePartVisit title={"Рентгенография "}/>
                <div className={"flex gap-5 items-start"}>
                    <div className={"w-60 text-sm"}>Снимки</div>
                    <div className={"radiography-list flex flex-col grow max-w-xl gap-3"}>
                        {
                            listRadiography && listRadiography.map((radiographyItemKey, index) => {
                                return <RadiographyVisitForm radiographyKey={radiographyItemKey} visit={visit} isOpenForm={openFormId == radiographyItemKey} onDelete={handleDelete} />
                            })
                        }
                        <div className={"mt-2"}>
                            <Button color={"lite"} label={"Добавить ренген"} onClick={handleAddRengen}/>
                        </div>
                    </div>
                </div>
            </div>
}

export default RengenPatientVisit;