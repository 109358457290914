import FieldSetVisitForm from "../Part/FieldSetVisitForm";
import EditFieldVisitForm from "../Part/EditFieldVisitForm";

const SocialIdentificationForm = ({visit}) => {

    return <FieldSetVisitForm title={"Социально-экономический статус"} id={"soc-status"}>
        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="education" typeFieldForm={"radio"} label={"Образование"} />
        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="employment" typeFieldForm={"radio"} label={"Работает ли пациент?"} />

    </FieldSetVisitForm>
}

export default SocialIdentificationForm;