import FieldSetVisitForm from "../Part/FieldSetVisitForm";
import {useEffect, useState} from "react";
import apiService from "../../../../../provider/ApiService";
import FieldChangeProvider from "../../../../../provider/FieldChangeProvider";
import fieldChangeProvider from "../../../../../provider/FieldChangeProvider";
import Button from "../../../../Part/Form/Button";
import CoefFisicalFieldVisitForm from "./FisicalTreatmentForm/CoefFisicalFieldVisitForm";
import PatientVisit from "../../../../../models/PatientVisit";

const SustavPoint = ({type, index, active, name,y, x, position="left", onClick}) => {
    const [isActive, setIsActive] = useState(active);

    useEffect(() => {
        setIsActive(active);
    }, [active]);

    const handleActive = () => {
        const isActiveB = !isActive;
        setIsActive(isActiveB);
        onClick(name, isActiveB);
    }

    const classList = isActive ? "border-green-pa " : "border ";
    const positionStyle = {};
    positionStyle["top"] = y+"%";
    if (position === "left") {
        positionStyle["left"] = x+"%";
    } else {
        positionStyle["right"] = x+"%";
    }

    return <div
        className={"w-3 h-3 bg-white border rounded-full absolute cursor-pointer flex items-center justify-center " + classList}
        style={positionStyle}
        onClick={handleActive}
    >
        {
            isActive &&
            <div className={"w-1.5 h-1.5 rounded-full bg-green-pa"}></div>
        }
    </div>
}

const SustavListInImage = ({type, joint, name, fetchJoin}) => {

    const [countSelected, setCountSelected] = useState(0);
    const [jointSelected, setJointSelected] = useState([]);

    useEffect(() => {
        setCountSelected(joint.length);
        setJointSelected(joint);
    }, [joint]);

    const pointsCoord = [
        {x: 35, y: 20},
        {x: 33, y: 34},
        {x: 29, y: 47},
        {x: 21, y: 47},
        {x: 15, y: 48},
        {x: 18, y: 54.5},
        {x: 22, y: 56},
        {x: 25, y: 57.5},
        {x: 29, y: 57.5},
        {x: 14, y: 57.5},
        {x: 17, y: 60},
        {x: 22.5, y: 61},
        {x: 29, y: 61},
        {x: 40, y: 67}
    ];

    const handelCountedActive = (name, active) => {

        if (active) {
            const index = jointSelected.indexOf(name);
            if (index === -1) {
                jointSelected.push(name);
            }
            setJointSelected(jointSelected);
            setCountSelected(joint.length);
        } else {
            const index = jointSelected.indexOf(name);
            if (index !== -1) {
                jointSelected.splice(index, 1); // Удалит 3
            }
            setCountSelected(jointSelected.length);
            setJointSelected(jointSelected);
        }
        const quant = active ? 1 : 0;
        fetchJoin(type, name, quant);
    }

    const handleSelectAll = (active) => {

        if (active) {
            fetchJoin(type, name+"0", 1);

            const cnt = pointsCoord.length*2;
            setCountSelected(cnt);
            const jointSelectedArr = [];
            for(let i = 1; i <= cnt; i++) {
                jointSelectedArr.push(name+i);
            }
            setJointSelected(jointSelectedArr);
        } else {
            fetchJoin(type, name+"0", 0);
            setCountSelected(0);
            setJointSelected([]);
        }
    }

    return <div className={"flex flex-col justify-center items-center gap-5"}>
        <div className={"flex justify-center "}>
            <div className={"relative"}>
                <img src={"/assets/images/fisical/people.png"}/>
                {
                    pointsCoord.map((item, index) => {
                        const nameSustav = name+(index+1).toString();
                        const active = jointSelected.includes(nameSustav);
                        return <SustavPoint index={index} active={active} type={type} y={item.y}  x={item.x} position={"left"} name={nameSustav} onClick={handelCountedActive}/>
                    })
                }
                {
                    pointsCoord.map((item, index) => {
                        const indexRight = pointsCoord.length + index + 1;
                        const nameSustav = name+indexRight.toString();
                        const active = jointSelected.includes(name+indexRight.toString());
                        return <SustavPoint index={indexRight} active={active} name={nameSustav} type={type} y={item.y}  x={item.x} position={"right"} onClick={handelCountedActive}/>
                    })
                }
            </div>
        </div>
        {
            type === "swollen" ?
                <div className={""}>
                    <span className={"uppercase mr-2"}>Число припухших суставов</span>
                    <strong>{countSelected}</strong>
                </div>
                :
                <div className={""}>
                    <span className={"uppercase mr-2"}>Число болезненных суставов</span>
                    <strong>{countSelected}</strong>
                </div>

        }
        <div className={"flex gap-3 w-full"}>
            <Button color={"lite"} size={"small"} label={"Выбрать все"} fullWidth={true} onClick={(e) => {handleSelectAll(true)}} />
            <Button color={"white"} size={"small"} label={"Сбросить"} fullWidth={true}  onClick={(e) => {handleSelectAll(false)}}/>
        </div>
    </div>

}

const FisicalTreatmentForm = ({visit}) => {

    const [visitInfo, setVisitInfo] = useState(visit);
    const [painfulJoint, setPainfulJoint] = useState([]);
    const [swollenJoint, setSwollenJoint] = useState([]);
    const [time, setTime] = useState(new Date().toLocaleTimeString());

    useEffect(() => {
        const createJoint = async () => {
            setPainfulJoint(await visit.getValue("painful_joint")) ;
            setSwollenJoint(await visit.getValue("swollen_joint")) ;
        }
        createJoint().catch(() => {

        });
    }, [time])

    const fetchJoin = async (type, value, active) => {
        const fields = ["das28soe", "das28srb", "sdai", "cdai"];

        const field = type === "swollen" ? "swollen_joint" : "painful_joint";
        const responseJson = await fieldChangeProvider.setFieldVisit(visit.getId(), field, value, active);
        const data = responseJson[field+"s"];
        fields.map((name) => {
            visitInfo.setValue(name, data[name]);
            visitInfo.setValue(name+"_txt", data[name+"_txt"]);
            visitInfo.setValue(name+"_color_hex", data[name+"_color_hex"]);
        });
        setTime(new Date().toLocaleTimeString());
        setVisitInfo(visitInfo);
    }

    return visitInfo && <FieldSetVisitForm title={"Физикальный осмотр пациента"} id={"base"}>
        <div className={"bg-white p-5 border rounded-sm"}>
            <div className={"grid grid-cols-2 gap-10"}>
                <SustavListInImage type={"swollen"} name={"swollen_joint_"} joint={swollenJoint} fetchJoin={fetchJoin}/>
                <SustavListInImage type={"painful"} name={"painful_joint_"} joint={painfulJoint} fetchJoin={fetchJoin}/>
            </div>
            <div className={""}>
                <CoefFisicalFieldVisitForm
                    title={"DAS28 - СОЭ"}
                    coef={visitInfo.getValue("das28soe")}
                    text={visitInfo.getValue("das28soe_txt")}
                    colorHex={visitInfo.getValue("das28soe_color_hex")}
                    valuesInfo={[
                        {name: "Ремиссия", value: "< 2,6"},
                        {name: "Низкая", value: "≤ 2,6  <= 3,2"},
                        {name: "Умеренная", value: "< 3,2  ≤ 5,1"},
                        {name: "Высокая", value: "> 5,1"},
                    ]}
                />
                <CoefFisicalFieldVisitForm
                    title={"DAS28 - СРБ"}
                    coef={visitInfo.getValue("das28srb")}
                    text={visitInfo.getValue("das28srb_txt")}
                    colorHex={visitInfo.getValue("das28srb_color_hex")}
                    valuesInfo={[
                        {name: "Ремиссия", value: "< 2,6"},
                        {name: "Низкая", value: "≤ 2,6  <= 3,2"},
                        {name: "Умеренная", value: "< 3,2  ≤ 5,1"},
                        {name: "Высокая", value: "> 5,1"},
                    ]}
                />
                <CoefFisicalFieldVisitForm
                    title={"SDAI"}
                    coef={visitInfo.getValue("sdai")}
                    text={visitInfo.getValue("sdai_txt")}
                    colorHex={visitInfo.getValue("sdai_color_hex")}
                    valuesInfo={[
                        {name: "Ремиссия", value: "≤3,3"},
                        {name: "Низкая", value: "<3,3   ≤11 "},
                        {name: "Умеренная", value: "<11  ≤26 "},
                        {name: "Высокая", value: ">26"},
                    ]}
                />
                <CoefFisicalFieldVisitForm
                    title={"CDAI"}
                    coef={visitInfo.getValue("cdai")}
                    text={visitInfo.getValue("cdai_txt")}
                    colorHex={visitInfo.getValue("cdai_color_hex")}
                    valuesInfo={[
                        {name: "Ремиссия", value: "< 2,8"},
                        {name: "Низкая", value: "≤ 2,8  ≤ 10 "},
                        {name: "Умеренная", value: "<10  ≤22 "},
                        {name: "Высокая", value: ">22"},
                    ]}
                />
            </div>
        </div>
    </FieldSetVisitForm>
}

export default FisicalTreatmentForm;