import {Link} from "react-router-dom";

const ButtonPrintMenu = ({visit}) => {
    return <Link to={"/patient/visitPrint/" + visit.getId()} className={"flex gap-3 bg-green-pa items-cente justify-center py-5 w-full"} target={"_blank"}>
        <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.6184 4.01071L8.77556 0.167857C8.66842 0.0607143 8.52377 0 8.37199 0H0.786272C0.470201 0 0.214844 0.255357 0.214844 0.571429V15.4286C0.214844 15.7446 0.470201 16 0.786272 16H12.2148C12.5309 16 12.7863 15.7446 12.7863 15.4286V4.41607C12.7863 4.26429 12.7256 4.11786 12.6184 4.01071ZM11.4684 4.67857H8.1077V1.31786L11.4684 4.67857ZM11.5006 14.7143H1.50056V1.28571H6.89342V5.14286C6.89342 5.34177 6.97243 5.53254 7.11309 5.67319C7.25374 5.81384 7.4445 5.89286 7.64342 5.89286H11.5006V14.7143Z" fill="white" fill-opacity="0.85"/>
        </svg>
        <span className={"text-white"}>Печать</span>
    </Link>
}

export default ButtonPrintMenu;