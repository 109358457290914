import React from 'react';
import PropTypes from 'prop-types';

const Boolean = ({ name, options, value, size = "medium", width= "w-40",  onChange = () => {}, ...rest }) => {

    const sizeClass = {
        small: 'py-1 px-2 text-sm',
        medium: 'py-2 px-4  text-base',
        large: 'p-3 text-lg'
    };

    return (
        <div className="radio-group__boolean flex  ">
            {options.map((option, index) => (
                <label key={index} className="radio-boolean">
                    <input
                        type="radio"
                        name={name}
                        value={option.value}
                        checked={value === option.value}
                        onChange={() => onChange(name, option.value)}
                        className="hidden "
                        {...rest}
                    />
                    <div
                        className={`${sizeClass[size]} ${width} rounded-sm  border cursor-pointer flex items-center justify-center ${
                            value === option.value ? 'color-green-pa border-green-pa bg-white' : ' text-gray-700'
                        }`}
                    >
                        {option.name}
                    </div>
                </label>
            ))}
        </div>
    );
};

Boolean.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func,
};

export default Boolean;