import FieldSetVisitForm from "../Part/FieldSetVisitForm";
import EditFieldVisitForm from "../Part/EditFieldVisitForm";

const GettingUpQuestionsForm = ({visit}) => {

    const options = [{name: "Без труда", value: "Без труда"},{name: "С некоторым затруднением", value: "С некоторым затруднением"},{name: "С большим трудом", value: "С большим трудом"},{name: "Не могу выполнить", value: "Не могу выполнить"}];
    return <FieldSetVisitForm title={"Вставание"} id={"base"}>
        <EditFieldVisitForm
            visit={visit}
            objectType={"visit"}
            fieldObject="able_stand_up"
            typeFieldForm={"radio"}
            label={"В состоянии ли вы встать с обычного стула без подлокотников?"}
            options={options}
        />
        <EditFieldVisitForm
            visit={visit}
            objectType={"visit"}
            fieldObject="get_in_and_out_of_bed"
            typeFieldForm={"radio"}
            label={"В состоянии ли вы лечь в кровать и встать с кровати?"}
            options={options}
        />
    </FieldSetVisitForm>
}

export default GettingUpQuestionsForm;