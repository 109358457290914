import FieldSetVisitForm from "../Part/FieldSetVisitForm";
import EditFieldVisitForm from "../Part/EditFieldVisitForm";

const GraspingMovementsQuestionsForm = ({visit}) => {

    const options = [{name: "Без труда", value: "Без труда"},{name: "С некоторым затруднением", value: "С некоторым затруднением"},{name: "С большим трудом", value: "С большим трудом"},{name: "Не могу выполнить", value: "Не могу выполнить"}];
    return <FieldSetVisitForm title={"Хватательные движения"} id={"base"}>
        <EditFieldVisitForm
            visit={visit}
            objectType={"visit"}
            fieldObject="open_a_car_door"
            typeFieldForm={"radio"}
            label={"В состоянии ли вы открыть дверь автомобиля"}
            options={options}
        />
        <EditFieldVisitForm
            visit={visit}
            objectType={"visit"}
            fieldObject="open_a_screw"
            typeFieldForm={"radio"}
            label={"В состоянии ли вы открыть банку с завинчивающейся крышкой, которую раньше уже открывали?"}
            options={options}
        />
        <EditFieldVisitForm
            visit={visit}
            objectType={"visit"}
            fieldObject="close_a_water_tap"
            typeFieldForm={"radio"}
            label={"В состоянии ли вы открыть и закрыть водопроводный кран?"}
            options={options}
        />
    </FieldSetVisitForm>
}

export default GraspingMovementsQuestionsForm;