import Button from "../../../../../Part/Form/Button";
import React, {useEffect, useState} from "react";
import EditFieldVisitForm from "../../Part/EditFieldVisitForm";
import fracturesProvider from "../../../../../../provider/FracturesProvider";
import fieldChangeProvider from "../../../../../../provider/FieldChangeProvider";
import DeleteButton from "../../../../../Part/Form/DeleteButton";
import anemiasProvider from "../../../../../../provider/AnemiasProvider";


const FieldSetForm = ({title, children}) => {
    return <div className={""}>
        <div className={"text-base mb-2"}>{title}</div>
        {children}
    </div>;
}


const EditForm = ({visit, itemKey, handleDelete }) => {

    const [objectEditor, setObjectEditor] = useState(null);


    useEffect( () => {

        const fetchObject = async (itemKey) => {
            const objectData = await anemiasProvider.getAnemias(itemKey);
            setObjectEditor(objectData);
        }

        fetchObject(itemKey) ;

    }, [visit, itemKey]);


    const yearOptions = [{name: "-", value: ""}];
    const year = new Date().getFullYear();
    for(let i = year; i > 1900; i-- ) {
        yearOptions.push({name: i, value: i});
    }

    const booleanOptions = [{name: "Нет", value: "0"}, {name: "Да", value: "1"}];


    if (objectEditor) {

        return <div className={"relative border bg-white p-5 flex flex-col  gap-5 "}>
            <div className={"absolute right-5 top-5"} >
                <DeleteButton
                    itemId={objectEditor.getId()}
                    onDelete={handleDelete}
                />
            </div>
            <FieldSetForm title={"Название"}>
                <EditFieldVisitForm visit={visit} typeFieldForm={"text"} objectType={"anemias"} fieldObject={"name"} instance={objectEditor} placeholder={"Напишите название"}/>
            </FieldSetForm>
            <div className={"flex gap-2 items-center"}>
                <span className={"text-base"}>Год установления</span>
                <EditFieldVisitForm visit={visit} typeFieldForm={"select"} objectType={"anemias"} fieldObject={"anemias_year"} instance={objectEditor} size={"small"} options={yearOptions} />
            </div>
            <FieldSetForm title={"Тип анемии"}>
                <EditFieldVisitForm visit={visit} typeFieldForm={"checkbox"} objectType={"anemias"} fieldObject={"anemias_type"} instance={objectEditor} hasButtonNewItem={true} hasOneValue={true} />
            </FieldSetForm>
        </div>
    }

    return  <div></div>;
}

const AnemiaTreatmentVisitForm = ({visit}) => {

    const [listKeys, setListKeys ] = useState([]);

    useEffect(() => {
        const fetchKeys = async () => {
            const keys = await visit.getPatient().getValue("anemias");
            if (keys) {
                setListKeys(keys);
            }
        }

        fetchKeys() ;

    },[visit]);


    const handleAdd = async () => {
        const data = await anemiasProvider.createAnemias(visit.getPatient().getIIN() );

        if (data && data.anemias_id) {
            const listData = [...listKeys];
            listData.push(data.anemias_id);
            setListKeys(listData);
        }
    }


    const handleDelete = async (key) => {
        await fieldChangeProvider.setFieldAnemias(key, "is_del", true , 1);
        const newList = listKeys.filter(item => item !== key);
        setListKeys(newList);
    }

    return <div className={"flex gap-5 items-start"}>
            <div className={"w-60 text-sm"}>Анемия</div>
            <div className={"radiography-list flex flex-col grow max-w-xl gap-3"}>
                {
                    listKeys && listKeys.map((itemKey, index) => {
                        return <EditForm itemKey={itemKey} visit={visit} handleDelete={handleDelete}/>
                    })
                }
                <div className={"mt-2"}>
                    <Button color={"lite"} size={"small"} label={"Добавить"} onClick={handleAdd}/>
                </div>
            </div>
        </div>;

}

export default AnemiaTreatmentVisitForm;