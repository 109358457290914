import EditFieldVisitForm from "../Part/EditFieldVisitForm";
import React, {useEffect, useState} from "react";
import analysProvider from "../../../../../provider/AnalysProvider";
import Input from "../../../../Part/Form/Input";

const AnalysResultItemTable = ({analysResultItem, analys, value,  isDisabled = false }) => {
    const [resultItem, setResultItem] = useState(null);
    const [internalValue, setInternalValue] = useState(value);

    useEffect(  () =>  {
        const fetchData = async () => {

            setResultItem(analysResultItem);
            setInternalValue(value);

            /*
            if (analysResultItem && analysResultItem.commonblood_test_id) {
                const resultItem = await analys.getResultItemById(analysResultItem.commonblood_test_id);
                setResultItem(resultItem);
            } else if (analysResultItem && analysResultItem.biochemical_test_id) {
                const resultItem = await analys.getResultItemById(analysResultItem.biochemical_test_id);
                setResultItem(resultItem);
            }
            */
        }

        fetchData();

    }, [analysResultItem, value]);

    const handleChange = (key, value) => {
        setInternalValue(value);
        analys.setValue("results", key, value, true);
    };

    //https://ra-api.medsystem.online//edit?user_id=user_0&key=ftxjmez&peremtype=analys&analys=analys_hfisjhn&peremname=results&results=commonblood_test_3&quant=25
    return (resultItem && <div className={"flex  items-center justify-between py-1  px-6"}>
        <div className={"w-60"}>{resultItem.name}</div>
        <div className={"w-24"}>
            <Input size={"small"} name={resultItem.value} value={String(internalValue)} placeholder={""} onChange={handleChange} close={false} disabled={isDisabled}  />
        </div>
        <div className={"w-24 text-right"}>{resultItem.measurement}</div>
    </div>);


}

export default AnalysResultItemTable;