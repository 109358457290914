import React from 'react';
import PropTypes from 'prop-types';

const Title = ({ label,  size = 'medium' }) => {
    const sizeClass = {
        small: 'text-base',
        medium: 'text-2xl',
        large: 'text-5xl'
    };


    return (
        <h1
            className={`rounded ${sizeClass[size]} uppercase font-medium`}
        >
            {label}
        </h1>
    );
};

Title.propTypes = {
    label: PropTypes.string.isRequired,
    size: PropTypes.oneOf(['small', 'medium', 'large'])
};

export default Title;