import FieldSetVisitForm from "../Part/FieldSetVisitForm";
import EditFieldVisitForm from "../Part/EditFieldVisitForm";

const DisabilityIdentificationForm = ({visit}) => {

    const options = [];

    for (let i = 0; i <= 50; i++) {
        options.push({name: i.toString(), value: i.toString()});
    }

    return <FieldSetVisitForm title={"Нетрудоспособность"} id={"sustavs"}>
        <EditFieldVisitForm options={options} visit={visit} objectType={"visit"} fieldObject="disability_days_inyear" typeFieldForm={"select"} label={"Количество дней нетрудоспособности по поводу РА за год"} width={"w-24"}/>
        <EditFieldVisitForm options={options} visit={visit} objectType={"visit"} fieldObject="hospitalization_days_inyear" typeFieldForm={"select"} label={"Количество госпитализаций по поводу РА за год"}  width={"w-24"}/>
        <EditFieldVisitForm options={options} visit={visit} objectType={"visit"} fieldObject="outpatient_visits_inyear" typeFieldForm={"select"} label={"Количество амбулаторных обращений по поводу РА за  год"}  width={"w-24"}/>
    </FieldSetVisitForm>
}

export default DisabilityIdentificationForm;