import React, { useEffect, useState } from "react";
import EditFieldVisitForm from "../Visit/Part/EditFieldVisitForm";
import Button from "../../../Part/Form/Button";
import Tooltip from "../../../Part/Form/Tooltip";
import { useHelp } from "../../../../provider/HelpProvider";

const IconEdit = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M11.5289 0.861766C11.7893 0.601417 12.2114 0.601417 12.4717 0.861766L15.1384 3.52843C15.3987 3.78878 15.3987 4.21089 15.1384 4.47124L8.47173 11.1379C8.34671 11.2629 8.17714 11.3332 8.00033 11.3332H5.33366C4.96547 11.3332 4.66699 11.0347 4.66699 10.6665V7.99984C4.66699 7.82303 4.73723 7.65346 4.86225 7.52843L11.5289 0.861766ZM6.00033 8.27598V9.99984H7.72418L13.7242 3.99984L12.0003 2.27598L6.00033 8.27598ZM2.66699 3.33317C2.2988 3.33317 2.00033 3.63165 2.00033 3.99984V13.3332C2.00033 13.7014 2.2988 13.9998 2.66699 13.9998H12.0003C12.3685 13.9998 12.667 13.7014 12.667 13.3332V9.77317C12.667 9.40498 12.9655 9.1065 13.3337 9.1065C13.7018 9.1065 14.0003 9.40498 14.0003 9.77317V13.3332C14.0003 14.4377 13.1049 15.3332 12.0003 15.3332H2.66699C1.56242 15.3332 0.666992 14.4377 0.666992 13.3332V3.99984C0.666992 2.89527 1.56242 1.99984 2.66699 1.99984H6.22699C6.59518 1.99984 6.89366 2.29831 6.89366 2.6665C6.89366 3.03469 6.59518 3.33317 6.22699 3.33317H2.66699Z" fill="#CCCDD3"/>
    </svg>
);

const FieldProfile = ({ iin, fieldObject, typeFieldForm, placeholder, label, fieldNameStart = null, fieldNameEnd = null, ...rest }) => {
    const [isEdit, setIsEdit] = useState(false);
    const [value, setValue] = useState("");
    const [options, setOptions] = useState([]);
    const [helpText, setHelpText] = useState("");
    const help = useHelp();

    useEffect(() => {
        if (help && fieldObject && help["h_" + fieldObject]) {
            setHelpText(help["h_" + fieldObject]);
        }
    }, [help, fieldObject]);

    useEffect(() => {
        const fetchOptionsAndValue = async () => {
            try {
                const [valueIIN, optionsIIN] = await Promise.all([
                    iin.getValue(fieldObject),
                    iin.getOptions(fieldObject)
                ]);
                setOptions(optionsIIN);

                if (typeFieldForm === "period") {
                    const start = await iin.getValue(fieldNameStart);
                    const end = await iin.getValue(fieldNameEnd);
                    updateValue(`${start} - ${end}`, optionsIIN);
                } else {
                    updateValue(valueIIN, optionsIIN);
                }
            } catch (error) {
                console.error('Error fetching data', error);
            }
        };

        fetchOptionsAndValue();
    }, [fieldObject, fieldNameStart, fieldNameEnd, typeFieldForm, iin]);

    const updateValue = (val, optionsIIN) => {
        if (!val) {
            setValue("-");
            return;
        }

        if (Array.isArray(optionsIIN) && optionsIIN.length > 0) {
            if (Array.isArray(val)) {
                const result = val.map(item => {
                    const option = optionsIIN.find(opt => opt.value === item);
                    return option ? option.name : null;
                }).filter(name => name !== null);
                setValue(result);
            } else {
                const option = optionsIIN.find(opt => opt.value === val);
                setValue(option ? option.name : val);
            }
        } else {
            setValue(val);
        }
    };

    const handleChange = async () => {
        try {
            const valueIIN = await iin.getValue(fieldObject);
            updateValue(valueIIN, options);
        } catch (error) {
            console.error('Error updating value', error);
        }
    };

    const handleSetEdit = () => setIsEdit(true);
    const handleSetCloseEdit = () => setIsEdit(false);

    return isEdit ? (
        <div className=" ">
            <EditFieldVisitForm
                objectType="iin"
                iin={iin}
                typeFieldForm={typeFieldForm}
                fieldObject={fieldObject}
                placeholder={placeholder}
                fieldNameStart={fieldNameStart}
                fieldNameEnd={fieldNameEnd}
                label={label}
                onChange={handleChange}
                {...rest}
            />
            <div className="ml-64 mt-2">
                <Button size="small" color="lite" onClick={handleSetCloseEdit} label="Сохранить" />
            </div>
        </div>
    ) : (
        <div className={"flex gap-5 " + (Array.isArray(value) ? "" : "items-center")}>
            <div className="text-sm text-left w-60">
                <label>{label}</label>
                {helpText && <Tooltip text={helpText} />}
            </div>
            <div className="text-base max-w-3xl overflow-wrap-pa">
                {Array.isArray(value) ? value.map((item, index) => <div key={index}>{item}</div>) : value}
            </div>
            <div onClick={handleSetEdit}>
                <IconEdit />
            </div>
        </div>
    );
};

export default FieldProfile;