import apiService from "./ApiService";
import Anemias from "../models/Anemias";

class AnemiasProvider {

    async getAnemias(anemiasKey) {
        const data = await apiService.post('/anemias_read', { anemias_id: anemiasKey });
        if (data && data.anemiass) {
            return new Anemias(data.anemiass);
        }
        return null;
    }

    async createAnemias(iin ) {
        const data = await apiService.post('/anemias_create', { iin: iin  });
        if (data) {
            return data;
        }
        return null;
    }

}

const anemiasProvider = new AnemiasProvider();
export default anemiasProvider;