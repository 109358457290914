import React, {useEffect, useState} from 'react';
import Tooltip from "./Tooltip";
import {useHelp} from "../../../provider/HelpProvider";


const FieldSet = ({  label, name = null,  children, ...rest  }) => {

    const [helpText, setHelpText] = useState("");
    const help = useHelp();

    useEffect(() => { 
        if (help && name && help["h_"+name] ) {
            setHelpText(help["h_"+name]);

        }
    }, [help])


    return (
        <div className={"flex gap-5 items-center"} {...rest}>
            <div className={"text-sm text-left w-60"}>
                <label>{label}</label>
                {helpText && <Tooltip text={helpText} />}
            </div>
            <div className={"grow max-w-3xl"}>
                {children}
            </div>

        </div>
    );
};

export default FieldSet;