import directoryProvider from "../provider/DirectoryProvider";
import fieldChangeProvider from "../provider/FieldChangeProvider";

class Oncology {
    constructor(data) {
        this.data = data;
    }

    getId() {
        return this.data["oncology_id"] ?? "";
    }

    getValue(key) {
        return this.data[key];
    }

    async setValue(key, value, quant = null, isServerChange = false) {
        this.data[key] = value;
        if (isServerChange) {
            const setServer = await fieldChangeProvider.setFieldOncology(this.getId(), key, value, quant);
            if (!setServer) {
                console.log("Error! Save Server");
            }
        }
    }


    async getOptions(fieldObject) {
        const directoryList = [];

        const notes = await this.getValue(fieldObject+"_notes");
        if ( directoryList.indexOf(fieldObject) !== -1 ) {
            let directory = await directoryProvider.getDirectory(fieldObject);

            if ( notes ) {
                const notesArray = Object.entries(notes);
                notesArray.forEach(item => {
                    directory.push({name: item[1], value: item[0], isDelete: true});
                });

            }

            return directory;
        }
        return [];
    }

}


export default Oncology;