import Button from "../../../Part/Form/Button";
import {useState} from "react";
import ButtonPrintMenu from "../../Sidebar/Menu/ButtonPrintMenu";


const SaveButtonPatientVisit = ({visit}) => {
    const [isSave, setIsSave] = useState(false);
    const [isCopy, setIsCopy] = useState(false);

    const handleSave = () => {
        setIsSave(true);

    }

    const handleCopy = () => {
        const currentUrl = window.location.href;
        navigator.clipboard.writeText(currentUrl).then(() => {
            console.log('Ссылка скопирована в буфер обмена:', currentUrl);
        }).catch(err => {
            console.error('Не удалось скопировать ссылку:', err);
        });
        setIsCopy(true);
        setTimeout(() => setIsCopy(false), 2000);
    }

    if (isSave) {
        return <div className={" pt-10 pb-20 px-10  border-b relative"}>
            <div className={"uppercase text-2xl text-center mb-4"}>
                Успех! Приём закончен, данные добавлены
            </div>
            <div className={"flex gap-10"}>
                <Button type={"button"} color={"lite"} label={!isCopy ? "Копировать ссылку на приём" : "Ссылка скопирована"} fullWidth={true} onClick={handleCopy}/>
                <ButtonPrintMenu visit={visit}/>
            </div>

        </div>
    } else {
        return <div className={" py-10 px-10  border-b"}>
            <Button type={"button"} color={"primary"} label={"Сохранить данные и завершить приём"} fullWidth={true} onClick={handleSave}/>
        </div>
    }

}

export default SaveButtonPatientVisit;