import FieldSetVisitForm from "../Part/FieldSetVisitForm";
import EditFieldVisitForm from "../Part/EditFieldVisitForm";

const PrivichkiIdentificationForm = ({visit}) => {

    return <FieldSetVisitForm title={"Вредные привычки"} id={"privichki"}>
        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="smoking" typeFieldForm={"boolean"} label={"Курение"} />

    </FieldSetVisitForm>
}

export default PrivichkiIdentificationForm;