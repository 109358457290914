// src/components/Cabinet.jsx
import React from 'react';
import HeaderLine from "../Cabinet/Header/HeaderLine";
import LeftSidebar from "../Cabinet/Sidebar/LeftSidevar";
import Empty from "../Cabinet/Content/Empty";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PatientProfileSidebar from "../Cabinet/Sidebar/PatientProfileSidebar";
import PatientFirstVisitSidebar from "../Cabinet/Sidebar/PatientFirstVisitSidebar";
import Content from "../Cabinet/Content/Content";
import PatientProfileContent from "../Cabinet/Content/PatientProfileContent";
import PatientFirstVisitContent from "../Cabinet/Content/PatientFirstVisitContent";
import SearchContent from "../Cabinet/Content/SearchContent";
import PatientNapravlenieContent from "../Cabinet/Content/PatientNapravlenieContent";
import PatientNewVisitContent from "../Cabinet/Content/PatientNewVisitContent";
import PatientVisitContent from "../Cabinet/Content/PatientVisitContent";
import {ListVisitsProvider} from "../../contexts/ListVisitsContext";
import PatientVisitSidebar from "../Cabinet/Sidebar/PatientVisitSidebar";
import {HelpProvider} from "../../provider/HelpProvider";
import HeaderInfo from "../Cabinet/Header/HeaderInfo";
import PatientVisitPrintContent from "../Cabinet/Content/PatientVisitPrintContent";

const Cabinet = ({ user }) => {

    return <div className=" min-h-screen bg-gray-pa">
                <HelpProvider>
                    <Router>
                            <div className={"sticky-header"}>
                                <HeaderInfo/>
                                <HeaderLine/>
                            </div>
                            <ListVisitsProvider>
                                <div className={"flex "}>
                                    <LeftSidebar>
                                        <Routes>
                                            <Route path="/patient/profile/:id" element={<PatientProfileSidebar/>} />
                                            <Route path="/patient/napravlenie/:id" element={<PatientProfileSidebar/>} />
                                            <Route path="/patient/new-visit/:id" element={<PatientProfileSidebar/>} />
                                            <Route path="/patient/visit/:id" element={<PatientVisitSidebar/>} />
                                            <Route path="/patient/first-visit" element={<PatientFirstVisitSidebar/>} />
                                        </Routes>
                                    </LeftSidebar>
                                    <Content>
                                        <Routes>
                                            <Route path="/search" element={<SearchContent />} />
                                            <Route path="/patient/profile/:id" element={<PatientProfileContent/>} />
                                            <Route path="/patient/napravlenie/:id" element={<PatientNapravlenieContent/>} />
                                            <Route path="/patient/new-visit/:id" element={<PatientNewVisitContent/>} />
                                            <Route path="/patient/visit/:id" element={<PatientVisitContent/>} />
                                            <Route path="/patient/first-visit" element={<PatientFirstVisitContent/>} />
                                            <Route path="/patient/visitPrint/:id" element={<PatientVisitPrintContent/>} />
                                            <Route path="/" element={<Empty/>}/>
                                        </Routes>
                                    </Content>
                                </div>
                            </ListVisitsProvider>
                    </Router>
                </HelpProvider>
            </div>;
};

export default Cabinet;