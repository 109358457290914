
import React, {useState} from 'react';
import ItemSidebarMenu from "./Menu/ItemSidebarMenu";
import {  useLocation, useParams } from "react-router-dom";
import NewVisitButton from "./Menu/NewVisitButton";
import ListVisits from "./Menu/ListVisits";

const PatientProfileSidebar = () => {

    const { id } = useParams();
    const location = useLocation();

    const svgItem1 = <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 2H11.82C11.4 0.84 10.3 0 9 0C7.7 0 6.6 0.84 6.18 2H2C0.9 2 0 2.9 0 4V18C0 19.1 0.9 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM9 2C9.55 2 10 2.45 10 3C10 3.55 9.55 4 9 4C8.45 4 8 3.55 8 3C8 2.45 8.45 2 9 2ZM11 16H4V14H11V16ZM14 12H4V10H14V12ZM14 8H4V6H14V8Z" fill="#CCCDD3"/>
        </svg>;

    const svgItem2 = <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 2H11.82C11.4 0.84 10.3 0 9 0C7.7 0 6.6 0.84 6.18 2H2C0.9 2 0 2.9 0 4V18C0 19.1 0.9 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM9 2C9.55 2 10 2.45 10 3C10 3.55 9.55 4 9 4C8.45 4 8 3.55 8 3C8 2.45 8.45 2 9 2ZM9 6C10.66 6 12 7.34 12 9C12 10.66 10.66 12 9 12C7.34 12 6 10.66 6 9C6 7.34 7.34 6 9 6ZM15 18H3V16.6C3 14.6 7 13.5 9 13.5C11 13.5 15 14.6 15 16.6V18Z" fill="#CCCDD3"/>
    </svg>;

    return (
        <div className={' '}>
            <ItemSidebarMenu
                href={`/patient/napravlenie/${id}`}
                label={"Направление мультидисциплинарной группы"}
                icon={svgItem1}
                active={location.pathname === `/patient/napravlenie/${id}`}
            />
            <ItemSidebarMenu
                href={`/patient/profile/${id}`}
                label={"Профиль пациента"}
                icon={svgItem2}
                active={location.pathname === `/patient/profile/${id}`}
            />
            <NewVisitButton
                iin={id}
                label={"Новый прием"}
            />
            <ListVisits/>
        </div>
    );
}

export default PatientProfileSidebar;