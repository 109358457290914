import React, { useState } from 'react';

const DropdownMenu = ({title, children}) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(prev => !prev);
    };

    return (
        <div className="relative">
            <div
                onClick={toggleMenu}
                className={"flex justify-between items-center cursor-pointer  " + (isOpen ? " " : "drop-down_close") }
            >
                <span className={"font-black text-sm uppercase"}>{title}</span>
                <svg className={"drop-down__svg " + (isOpen ? " " : "drop-down__svg_close")} width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 5L5 1L9 5" stroke="#797979" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            {isOpen && (
                <div className={"flex flex-col gap-2 mt-2"}>
                    {children}
                </div>
            )}
        </div>
    );
};

export default DropdownMenu;