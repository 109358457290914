
import React, {useState, useEffect} from 'react';
import FieldSet from "../../Part/Form/FieldSet";
import Input from "../../Part/Form/Input";
import TitleModal from "../../Part/Modal/TitleModal";
import Row from "../../Part/Form/Row";
import Button from "../../Part/Form/Button";
import apiService from "../../../provider/ApiService";

const UserInfoModal = () => {

    const [error, setError] = useState('');

    const fio = localStorage.getItem("fio");
    const jobTitle = localStorage.getItem("job_title");

    const handleLogout = () => {
        console.log("Logging out...");
        localStorage.removeItem('key');
        localStorage.removeItem('user_id');
        localStorage.removeItem('fio');
        localStorage.removeItem('job_title');
        window.location.reload();
    }


    return <div className={"modal-pa"} id={"user-info-modal"}  >
        <TitleModal label={"Информация о пользователе"}/>
        <FieldSet>
            <Row label={"ФИО"}>
                <Input value={fio} disabled close={false}/>
            </Row>
            <Row label={"Должность"}>
                <Input value={jobTitle} disabled close={false} />
            </Row>
            <Row label={""}>
                <Button type={"button"} label={"Выйти"} color={"secondary"} fullWidth={true} onClick={handleLogout}/>
            </Row>
        </FieldSet>
    </div>
}


export default UserInfoModal;