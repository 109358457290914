import FieldSetVisitForm from "./Part/FieldSetVisitForm";
import EditFieldVisitForm from "./Part/EditFieldVisitForm";
import React from "react";


const DatePatientVisit = ({visit}) => {

    const today = new Date().toISOString().split('T')[0];
    return <div className={" flex gap-2 items-center ml-14"}>
        <span>Дата визита: </span>
        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="appear_time" typeFieldForm={"date"} label={""} size={"small"} max={today} />
    </div>
}

export default DatePatientVisit;