
import React, {useState} from 'react';

const LeftSidebar = ({children}) => {

    return <div className={'border-r w-60 min-h-screen sticky-sidebar'}>
        {children}
    </div>
}

export default LeftSidebar;