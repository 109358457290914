import FieldSetVisitForm from "../Part/FieldSetVisitForm";
import EditFieldVisitForm from "../Part/EditFieldVisitForm";

const SustavTreatmentForm = ({visit}) => {

    return <FieldSetVisitForm title={"Поражение суставов"} id={"base"}>
        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="brushes" typeFieldForm={"checkbox"} label={"Кисти"} hasButtonNewItem={true}/>
        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="knee_joints" typeFieldForm={"checkbox"} label={"Коленные суставы"} hasButtonNewItem={true}/>
        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="feet" typeFieldForm={"checkbox"} label={"Стопы"} hasButtonNewItem={true}/>
        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="hip_joints" typeFieldForm={"checkbox"} label={"Тазобедренные суставы"}  hasButtonNewItem={true} />
        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="shoulder" typeFieldForm={"checkbox"} label={"Плечевые"}  hasButtonNewItem={true} />
        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="elbow_joints" typeFieldForm={"checkbox"} label={"Локтевые суставы"}  hasButtonNewItem={true} />
        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="other_joints" typeFieldForm={"checkbox"} label={"Другие суставы"}  hasButtonNewItem={true} />

    </FieldSetVisitForm>
}

export default SustavTreatmentForm;