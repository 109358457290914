import React from 'react';
import PropTypes from 'prop-types';

const TitleModal = ({ label,  size = 'medium' }) => {
    const sizeClass = {
        small: 'text-base',
        medium: 'text-2xl',
        large: 'text-5xl'
    };


    return (
        <div
            className={`rounded ${sizeClass[size]} uppercase font-medium mb-8 border-b pb-4`}
        >
            {label}
        </div>
    );
};

TitleModal.propTypes = {
    label: PropTypes.string.isRequired,
    size: PropTypes.oneOf(['small', 'medium', 'large'])
};

export default TitleModal;