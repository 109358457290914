import apiService from "./ApiService";
import IZL from "../models/IZL";
import TunnelSyndromes from "../models/TunnelSyndromes";

class TunnelSyndromesProvider {

    async get(key) {
        const data = await apiService.post('/tunnel_syndromes_read', { tunnel_syndromes_id: key });
        if (data && data.tunnel_syndromess) {
            return new TunnelSyndromes(data.tunnel_syndromess);
        }
        return null;
    }

    async create(iin ) {
        const data = await apiService.post('/tunnel_syndromes_create', { iin: iin  });
        if (data) {
            return data;
        }
        return null;
    }

}

const tunnelSyndromesProvider = new TunnelSyndromesProvider();
export default tunnelSyndromesProvider;