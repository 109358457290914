import apiService from "./ApiService";
import IZL from "../models/IZL";

class IZLProvider {

    async get(key) {
        const data = await apiService.post('/izl_read', { izl_id: key });
        if (data && data.izls) {
            return new IZL(data.izls);
        }
        return null;
    }

    async create(iin ) {
        const data = await apiService.post('/izl_create', { iin: iin  });
        if (data) {
            return data;
        }
        return null;
    }

}

const izlProvider = new IZLProvider();
export default izlProvider;