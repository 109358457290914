import apiService from "./ApiService";
import IZL from "../models/IZL";
import Subluxation from "../models/Subluxation";

class SubluxationProvider {

    async get(key) {
        const data = await apiService.post('/subluxation_read', { subluxation_id: key });
        if (data && data.subluxations) {
            return new Subluxation(data.subluxations);
        }
        return null;
    }

    async create(iin ) {
        const data = await apiService.post('/subluxation_create', { iin: iin  });
        if (data) {
            return data;
        }
        return null;
    }

}

const subluxationProvider = new SubluxationProvider();
export default subluxationProvider;