
import React, {useState} from 'react';
import {Link} from "react-router-dom";

const ItemSidebarMenu = ({href, label, icon, active}) => {


    return <Link to={href} className={'menu-item px-5 py-2.5 bg-white border-b flex gap-1.5 justify-start items-center ' + (active ? 'menu-item_active' : '')}>
        <div className={" flex items-center justify-center w-6 h-6"}>{icon}</div>
        <div className={"menu-item__label uppercase overflow-hidden font-bold text-sm grow w-40"}>{label}</div>
    </Link>
}

export default ItemSidebarMenu;