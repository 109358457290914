import React, { createContext, useContext, useState, useEffect } from 'react';
import apiService from "./ApiService";

const HelpContext = createContext();

export const HelpProvider = ({ children }) => {
    const [help, setHelp] = useState([]);

    useEffect(() => {
        const fetchHelp = async () => {
            const help = await apiService.get('/help', {});
            if (help && help.helps) {
                setHelp(help.helps);
            }
        };
        fetchHelp();
    }, []);

    return (
        <HelpContext.Provider value={help}>
            {children}
        </HelpContext.Provider>
    );
};

export const useHelp = () => {
    return useContext(HelpContext);
};