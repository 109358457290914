import apiService from "./ApiService";
import IHD from "../models/IHD";
import Diabet from "../models/Diabet";

class DiabetProvider {

    async get(key) {
        const data = await apiService.post('/diabetes_mellitus_read', { diabetes_mellitus_id: key });
        if (data && data.diabetes_mellituss) {
            return new Diabet(data.diabetes_mellituss);
        }
        return null;
    }

    async create(iin ) {
        const data = await apiService.post('/diabetes_mellitus_create', { iin: iin  });
        if (data) {
            return data;
        }
        return null;
    }

}

const diabetProvider = new DiabetProvider();
export default diabetProvider;