import apiService from "./ApiService";
import ONMK from "../models/ONMK";
import Tuberculosis from "../models/Tuberculosis";

class TuberculosProvider {

    async get(key) {
        const data = await apiService.post('/tuberculosis_read', { tuberculosis_id: key });
        if (data && data.tuberculosiss) {
            return new Tuberculosis(data.tuberculosiss);
        }
        return null;
    }

    async create(iin ) {
        const data = await apiService.post('/tuberculosis_create', { iin: iin  });
        if (data) {
            return data;
        }
        return null;
    }

}

const tuberculosProvider = new TuberculosProvider();
export default tuberculosProvider;